import { Carousel } from '@mantine/carousel'
import {
	Anchor,
	Box,
	Card,
	em,
	Flex,
	Image,
	Progress,
	rem,
	Text,
} from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'
import {
	IconArrowLeft,
	IconArrowRight,
	IconChevronRight,
} from '@tabler/icons-react'
import { Fragment, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import left from '../../../assets/left.svg'
import right from '../../../assets/right.svg'
import crazyStar from '../../../assets/star.png'
import storyPoint from '../../../assets/story-point.png'
import RestaurantsCardsNew from '../../../components/RestaurantsCardsNew.tsx'
import CategorySkeleton from '../../../components/skeletons/CategorySkeleton.tsx'
import ProductInfoSkeleton from '../../../components/skeletons/ProductInfoSkeleton.tsx'
import RestaurantsSkeleton from '../../../components/skeletons/RestaurantsSkeleton.tsx'
import { getAllProductsAction } from '../../../store/product/productActions.ts'
import { useAppDispatch, useAppSelector } from '../../../store/store.ts'
import { getCentre, getUser } from '../../../utils/storage.ts'
import ProductCardNew from '../product/ProductCardNew.tsx'
import classes from './carousel.module.css'
import Home from './Home.tsx'

const HomePage = () => {
	const dispatch = useAppDispatch()
	const navigate = useNavigate()
	const { products, loaders } = useAppSelector((state) => state.product)
	// const centre = getCentre()

	const isMobile = useMediaQuery(`(max-width: ${em(768)})`)

	// const user = getUser()
	// const userId = getUserId()

	// Get centre and user info once during component mount
	const centre = useMemo(() => getCentre(), [])
	const user = useMemo(() => getUser(), [])

	// Track component mount state
	const [isInitialized, setIsInitialized] = useState(false)

	// Handle initial data fetching
	useEffect(() => {
		const initializeData = async () => {
			try {
				await dispatch(getAllProductsAction(centre))
				setIsInitialized(true)
			} catch (error) {
				console.error('Failed to fetch initial data:', error)
				// Handle error appropriately
			}
		}

		if (!isInitialized && centre) {
			initializeData()
		}
	}, [dispatch, centre, isInitialized])

	// reusable

	return (
		<Home
			slider={products?.data?.homeSlides}
			topCagtories={products?.data?.topCategories}
			loading={loaders.gettingAllProducts}
		>
			<Flex direction='column' maw={isMobile ? '100%' : '75%'}>
				<Flex direction='column' mb='md'>
					<Flex
						hiddenFrom='lg'
						justify='space-evenly'
						ml={isMobile ? 'lg' : ''}
						align='center'
						wrap={isMobile ? 'wrap' : 'nowrap'}
						mb='xl'
						gap='sm'
						pb='lg'
						w={isMobile ? '90%' : '100%'}
						h={isMobile ? 150 : 150}
					>
						{loaders.gettingAllProducts
							? [1, 2, 3, 4, 5, 6, 7, 8].map((_, index) => (
									<CategorySkeleton key={index} />
							  ))
							: products?.data?.topCategories?.map((value, index) => {
									return (
										<Flex
											h={isMobile ? '40%' : '50%'}
											w={isMobile ? 70 : 70}
											my='lg'
											align='center'
											justify='space-between'
											pos='relative'
											key={index}
											direction='column'
											gap={isMobile ? ' ' : 'sm'}
											style={{ cursor: 'pointer' }}
											onClick={() => {
												const params = encodeURIComponent(value.name)
												navigate(`/category?category=${params}`)
											}}
										>
											<Box style={{ zIndex: 2 }} w={isMobile ? 50 : 70}>
												<Box h={50}> 
													<Image
														right={15}
														h={'100%'}
														src={value.image}
														fallbackSrc='https://placehold.co/600x400?text=Image'
													/>
												</Box>
											</Box>
											<Card
												radius='md'
												component='a'
												style={{ zIndex: 1 }}
												opacity={0.3}
												top={isMobile ? -10 : -15}
												bg='#62BE12'
												pos='absolute'
												h={isMobile ? 50 : 70}
												w={isMobile ? 50 : 70}
											></Card>
											<Text
												ta='center'
												size={isMobile ? 'sm' : ''}
												fw={isMobile ? 500 : 700}
											>
												{value.name}
											</Text>
										</Flex>
									)
							  })}
					</Flex>
					<Card
						onClick={() => navigate('/coupons')}
						withBorder
						mt={isMobile ? 'xl' : ''}
						radius='xl'
						hiddenFrom='sm'
						mx='sm'
						p={0}
						h='100px'
						style={{ background: '#62BE12' }}
					>
						<Box h={70} w={70}>
							<Image w={70} h={65} src={left} pos='absolute' />
						</Box>
						<Flex align='center' h='100%' justify='space-between' m='xs'>
							<Box>
								<Image h={60} w={60} src={storyPoint} pos='absolute' top={20} />
							</Box>
							<Flex direction='column' w='65%' ml='xl'>
								<Flex gap='xs' align='center' mb={2}>
									<Text c='white' ta='center' size='14px' fw={200}>
										{user?.loyaltyPoints ? Math.round(user?.loyaltyPoints) : 0}{' '}
										CRAZY Stars{' '}
									</Text>
									<Image h={20} w={20} src={crazyStar} />
								</Flex>
								<Progress
									color='#274C07'
									bg='#45850D'
									value={user?.loyaltyPoints ? user?.loyaltyPoints / 5 : 0}
								/>
								<Text my={2} c='white' size='xs'>
									Join{' '}
									<Text span fw={700}>
										CRAZY CUB
									</Text>
									, an exclusive loyalty and rewards program!
								</Text>
							</Flex>
							<IconChevronRight color='white' />
						</Flex>
						<Image
							h='100%'
							w={71}
							pos='absolute'
							right={-10}
							top={16}
							src={right}
						/>
					</Card>
					<Flex my='lg' justify='space-between' m='xs'>
						<Text fw={700}>
							{products?.data?.categoryProducts[0]?.category}
						</Text>
						<Anchor
							size={isMobile ? 'sm' : ''}
							style={{ color: '#62BE12' }}
							underline='never'
							onClick={() => {
								const param = encodeURIComponent(
									products?.data?.categoryProducts[0]?.category
								)
								navigate(`/category?category=${param}`, { state: 'viewAll' })
							}}
						>
							View all
						</Anchor>
					</Flex>
					<Carousel
						nextControlIcon={
							<IconArrowRight
								style={{
									width: rem(30),
									height: rem(30),
									boxShadow: '5px 5px 10px 2px rgba(0,0,0,.4)',
									borderRadius: '100px',
								}}
							/>
						}
						previousControlIcon={
							<IconArrowLeft
								style={{
									width: rem(30),
									height: rem(30),
									boxShadow: '5px 5px 10px 2px rgba(0,0,0,.4)',
									borderRadius: '100px',
								}}
							/>
						}
						slideSize={isMobile ? '160px' : '160px'}
						slideGap={0}
						align='start'
						dragFree
						classNames={classes}
					>
						{loaders.gettingAllProducts
							? [1, 2, 3, 4, 5, 6, 7].map((_, index) => (
									<Carousel.Slide key={index}>
										<ProductInfoSkeleton />
									</Carousel.Slide>
							  ))
							: products?.data?.categoryProducts[0]?.products?.map(
									(product, index) => {
										return (
											<Carousel.Slide key={index}>
												<ProductCardNew product={product} />
											</Carousel.Slide>
										)
									}
							  )}
					</Carousel>
					<Flex w={'100%'} justify='space-between' m='xs'>
						<Text fw={700}>Restaurants near you</Text>
						<Anchor
							size={isMobile ? 'sm' : ''}
							underline='never'
							style={{ color: '#62BE12' }}
							onClick={() => {
								navigate('/restaurants')
							}}
						>
							View all
						</Anchor>
					</Flex>
					<Carousel
						nextControlIcon={
							<IconArrowRight
								style={{
									width: rem(30),
									height: rem(30),
									boxShadow: '5px 5px 10px 2px rgba(0,0,0,.4)',
									borderRadius: '100px',
								}}
							/>
						}
						previousControlIcon={
							<IconArrowLeft
								style={{
									width: rem(30),
									height: rem(30),
									boxShadow: '5px 5px 10px 2px rgba(0,0,0,.4)',
									borderRadius: '100px',
								}}
							/>
						}
						slideSize='180px'
						// slideSize={350}
						slideGap={0}
						align='start'
						dragFree
						classNames={classes}
						mb='sm'
					>
						{loaders.gettingAllProducts
							? [1, 2, 3, 4, 5, 6, 7].map((_, index) => (
									<Carousel.Slide key={index}>
										<RestaurantsSkeleton />
									</Carousel.Slide>
							  ))
							: // <Loader/>
							  products?.data?.nearbyRestaurants?.map((c, index) => {
									return <RestaurantsCardsNew key={index} restaurant={c} />
							  })}
					</Carousel>
					{/*</ScrollArea>*/}
					{products?.data?.categoryProducts.map((c, index) => {
						// if (c.category.includes('Grocery')) return
						return (
							<Fragment key={index}>
								<Flex justify='space-between' mx='sm'>
									<Text fw={700}>{c?.category}</Text>
									<Anchor
										size={isMobile ? 'sm' : ''}
										underline='never'
										style={{ color: '#62BE12' }}
										onClick={() => {
											const param = encodeURIComponent(c.category)
											navigate(`/category?category=${param}`, {
												state: 'viewAll',
											})
										}}
									>
										View all
									</Anchor>
								</Flex>
								<Carousel
									nextControlIcon={
										<IconArrowRight
											style={{
												width: rem(30),
												height: rem(30),
												boxShadow: '5px 5px 10px 2px rgba(0,0,0,.4)',
												borderRadius: '100px',
											}}
										/>
									}
									previousControlIcon={
										<IconArrowLeft
											style={{
												width: rem(30),
												height: rem(30),
												boxShadow: '5px 5px 10px 2px rgba(0,0,0,.4)',
												borderRadius: '100px',
											}}
										/>
									}
									slideSize={isMobile ? '160px' : '160px'}
									slideGap={0}
									align='start'
									dragFree
									classNames={classes}
								>
									{loaders.gettingAllProducts
										? [1, 2, 3, 4, 5, 6, 7].map((_, index) => (
												<Carousel.Slide key={index}>
													<ProductInfoSkeleton />
												</Carousel.Slide>
										  ))
										: c.products?.map((product, index) => {
												return (
													<Carousel.Slide key={index}>
														<ProductCardNew product={product} />
													</Carousel.Slide>
												)
										  })}
								</Carousel>
							</Fragment>
						)
					})}
				</Flex>
			</Flex>
		</Home>
	)
}

export default HomePage
