import { Carousel } from '@mantine/carousel'
import {
    Box,
    Button,
    Card,
    em,
    Flex,
    Image,
    List,
    rem,
    Slider,
    Text,
    ThemeIcon,
    Title,
} from '@mantine/core'
import { IconCircleCheck } from '@tabler/icons-react'
import { useMediaQuery } from '@mantine/hooks'
import { useRef } from 'react'
import { useNavigate } from 'react-router-dom'
// import img from "../../assets"
import card2 from '../../../assets/Loyality Program screen (Assets)/discount/rafiki.png'
import icon1 from '../../../assets/Loyality Program screen (Assets)/icon 1.png'
import icon2 from '../../../assets/Loyality Program screen (Assets)/icon 2.png'
import icon3 from '../../../assets/Loyality Program screen (Assets)/icon 3.png'
import icon4 from '../../../assets/Loyality Program screen (Assets)/icon 4.png'
import icon5 from '../../../assets/Loyality Program screen (Assets)/icon 5.png'
import card1 from '../../../assets/Loyality Program screen (Assets)/money-income/pana.png'
import card3 from '../../../assets/Loyality Program screen (Assets)/money-motivation/amico.png'
import shoppingBag from '../../../assets/Loyality Program screen (Assets)/shopping-bag/amico.png'
import img4 from '../../../assets/arco.png'
import img1 from '../../../assets/citgo.png'
import loyaltyMap from '../../../assets/loyalty map.png'
import playstoreImage from '../../../assets/playstore.png'
import img3 from '../../../assets/shell.png'
import img2 from '../../../assets/sunoco.png'
import Colors from "../../../constants/colors.ts";

const items = [
    {
        image: card1,
        title: 'Earn Points',
        description: 'Every purchase adds points to your loyalty progress.',
    },
    {
        image: card3,
        title: 'Track Progress',
        description: 'Watch your points grow on your dashboard’s progress bar.',
    },
    {
        image: card2,
        title: 'Unlock Discounts',
        description:
            'Once you complete the progress bar, enjoy discounts tailored to your shopping habits.',
    },
]

type ICard = {
    img: string
    title: string
    description: string
    style?: React.CSSProperties // Inline styles
}

const Loyalty = () => {
    const containerRef = useRef(null)
    const isMobile = useMediaQuery(`(max-width: ${em(768)})`)
    const navigate = useNavigate()

    const processCard = items.map((card, index) => {
        return (
            <Card key={index} w={350}>
                <Flex direction={'column'} align={'center'} gap={10}>
                    <Image h={250} w={250} src={card.image} />
                    <Title fw={'500'}>{card.title}</Title>
                    <Text c={'#404040'} ta={'center'}>
                        {card.description}
                    </Text>
                </Flex>
            </Card>
        )
    })

    function CardBox({ style, img, title, description }: ICard) {
        const cardRef = useRef(null)

        return (
            <Box
                ref={cardRef}
                style={{
                    textAlign: 'center',
                    ...style, // Applies the specific position for each card
                }}
            >
                <Flex
                    direction={isMobile ? 'column' : 'row'}
                    align={isMobile ? 'center' : 'start'}
                    gap={isMobile ? 0 : 20}
                >
                    {/* Image */}
                    <Box
                        style={{
                            height: isMobile ? '100px' : '260px',
                            width: isMobile ? '100px' : '260px',
                            margin: '0 auto',
                            borderRadius: '50%',
                            backgroundColor: '#f4f4f4',
                            position: 'relative',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <Image src={img} alt={title} fit='contain' />
                    </Box>

                    <Flex
                        direction={'column'}
                        mt={isMobile ? 0 : 20}
                        align={isMobile ? 'center' : 'start'}
                    >
                        {/* Title */}
                        <Text mt='sm' fw={'bold'} fz={isMobile ? 32 : 42}>
                            {title}
                        </Text>

                        {/* Description */}
                        <Text
                            fz={isMobile ? 18 : 20}
                            w={250}
                            c={'#404040'}
                            ta={isMobile ? 'center' : 'start'}
                        >
                            {description}
                        </Text>
                    </Flex>
                </Flex>
            </Box>
        )
    }

    return (
        <>
            <Flex direction={'column'} w={'100%'}>
                <Flex
                    w={'100%'}
                    align={'center'}
                    direction={isMobile ? 'column' : 'row'}
                    justify={'space-between'}
                    p={isMobile ? 20 : 50}
                    gap={isMobile ? 20 : 0}
                >
                    <Flex direction={'column'} gap={20}>
                        <Title fz={isMobile ? 34 : 65}>
                            The power to{' '}
                            <span
                                style={{
                                    color: Colors.primaryColor,
                                }}
                            >
								earn more
							</span>
                        </Title>
                        <Text>
                            Track your progress, complete points, and unlock exciting
                            discounts based on your shopping.
                        </Text>
                        <Button
                            color='black'
                            c={Colors.primaryColor}
                            w={'max-content'}
                            radius={'xl'}
                            size='lg'
                            style={{
                                boxShadow: '-4px 29px 48px -2px rgba(170,225,4,0.36)',
                            }}
                            onClick={() => {
                                navigate('/loyalty-tracking')
                                window.scrollTo({
                                    behavior: 'smooth',
                                    top: 0,
                                })
                            }}
                        >
                            Join Now
                        </Button>
                    </Flex>
                    <Flex direction={'column'} gap={20}>
                        <Box h={isMobile ? 400 : 800}>
                            <Image h={'100%'} src={loyaltyMap} />
                        </Box>
                    </Flex>
                </Flex>
                <Flex
                    w={'100%'}
                    direction={'column'}
                    align={'center'}
                    justify={'space-between'}
                    p={50}
                >
                    <Flex justify={'center'} w={'100%'}>
                        <Title fz={isMobile ? 34 : 65} ta={'center'}>
                            How it{' '}
                            <span
                                style={{
                                    color: Colors.primaryColor,
                                }}
                            >
								works?
							</span>{' '}
                        </Title>
                    </Flex>
                    <Flex
                        gap={isMobile ? 30 : 130}
                        my={39}
                        direction={isMobile ? 'column' : 'row'}
                        justify={'space-between'}
                    >
                        {processCard}
                    </Flex>
                </Flex>
                {/* <Flex
					bg={'#1E1E1E'}
					wrap={'wrap'}
					p={40}
					px={isMobile ? 20 : 80}
					gap={isMobile ? 20 : 0}
					mt={39}
				> */}
                <Carousel
                    mb={39}
                    withIndicators
                    height={200}
                    slideSize={{ base: '100%', sm: '50%', md: '10%' }}
                    slideGap={{ base: 0, sm: 'md' }}
                    loop
                    align={isMobile ? 'start' : 'center'}
                    slidesToScroll={1}
                >
                    <Carousel.Slide>
                        <Image
                            src={img1}
                            h={isMobile ? '100%' : 100}
                            w={isMobile ? '100%' : 150}
                        />
                    </Carousel.Slide>
                    <Carousel.Slide>
                        <Image
                            src={img2}
                            h={isMobile ? '100%' : 100}
                            w={isMobile ? '100%' : 150}
                        />
                    </Carousel.Slide>
                    <Carousel.Slide>
                        <Image
                            src={img3}
                            h={isMobile ? '100%' : 100}
                            w={isMobile ? '100%' : 150}
                        />
                    </Carousel.Slide>
                    <Carousel.Slide>
                        <Image
                            mx={isMobile ? 'auto' : ''}
                            src={img4}
                            h={isMobile ? 'auto' : 100}
                            w={isMobile ? '160' : 80}
                        />
                    </Carousel.Slide>
                </Carousel>
                {/* </Flex> */}
                <Flex
                    p={isMobile ? 20 : 80}
                    bg={'#F1F1F1'}
                    mb={39}
                    gap={isMobile ? 30 : 0}
                    justify={'space-evenly'}
                    direction={isMobile ? 'column' : 'row'}
                    align={'center'}
                >
                    <Flex direction={'column'} gap={isMobile ? 20 : 50}>
                        <Title fz={isMobile ? 34 : 65}>
                            <span style={{ color: Colors.primaryColor }}>Why to</span> Join?
                        </Title>
                        <List
                            spacing={isMobile ? 'md' : 'xl'}
                            size={isMobile ? 'md' : 'xl'}
                            center
                            icon={
                                <ThemeIcon color={Colors.primaryColor} size={30} radius='xl'>
                                    <IconCircleCheck
                                        style={{ width: rem(16), height: rem(16) }}
                                    />
                                </ThemeIcon>
                            }
                        >
                            <List.Item>Save more with every purchase.</List.Item>
                            <List.Item>Simple, automatic tracking of rewards.</List.Item>
                            <List.Item>Exclusive discounts just for you.</List.Item>
                        </List>
                        <Button c={'white'} size='md' w={'max-content'} radius={'xl'}>
                            Shop Now and Earn Points
                        </Button>
                    </Flex>
                    <Box>
                        <Image src={shoppingBag} h={isMobile ? 200 : 600}></Image>
                    </Box>
                </Flex>
                <Flex
                    p={isMobile ? 20 : 80}
                    direction={'column'}
                    mb={39}
                    justify={'space-evenly'}
                >
                    <Flex
                        mb={29}
                        w={'100%'}
                        gap={10}
                        justify={'center'}
                        direction={'column'}
                        align={'center'}
                    >
                        <Title fz={isMobile ? 34 : 65}>
                            How do{' '}
                            <span
                                style={{
                                    color: Colors.primaryColor,
                                }}
                            >
								I get a reward?
							</span>{' '}
                        </Title>
                        <Text>
                            Earning rewards with Crazy By Rasel is easy and fun! Follow these
                            simple steps:
                        </Text>
                    </Flex>
                    <Box
                        ref={containerRef}
                        style={{
                            position: 'relative',
                            padding: '2rem',
                            height: '100%',
                        }}
                    >
                        {/* Cards */}
                        {/* Static Cards */}
                        <Flex
                            justify={'space-between'}
                            direction={isMobile ? 'column' : 'row'}
                        >
                            <CardBox
                                // style={{ left: '25%', transform: 'translateX(-100%)' }}
                                img={icon1}
                                title='Register'
                                description='To start redeeming and bag a point'
                            />
                            <CardBox
                                style={{
                                    margin: '60px 0px',
                                }}
                                img={icon2}
                                title='Shop Regularly'
                                description='You earn points for every dollar spent'
                            />
                        </Flex>
                        <Flex
                            justify={'space-between'}
                            direction={isMobile ? 'column' : 'row'}
                        >
                            <CardBox
                                // style={{ left: '25%', transform: 'translateX(-100%)' }}
                                img={icon3}
                                title='Redeem Your Rewards'
                                description='Once your progress bar is full, unlock exclusive discounts based on your total shopping'
                            />
                            <CardBox
                                style={{
                                    margin: '60px 0px',
                                }}
                                img={icon4}
                                title='Track Your Progress'
                                description='Keep an eye on your progress bar in your dashboard'
                            />
                        </Flex>
                        <Flex justify={'center'} direction={isMobile ? 'column' : 'row'}>
                            <CardBox
                                // style={{
                                // 	left: '50%',
                                // 	transform: 'translateX(-50%)',
                                // 	marginTop: '40px',
                                // }}
                                img={icon5}
                                title='Enjoy the Perks'
                                description='Crazy By Rasel ensures your loyalty is always rewarded'
                            />
                        </Flex>
                    </Box>
                </Flex>
                <Flex
                    bg={'#E1E1E1'}
                    p={isMobile ? 20 : 80}
                    gap={isMobile ? 20 : 0}
                    mt={39}
                    justify={'space-evenly'}
                    direction={isMobile ? 'column' : 'row'}
                    align={'center'}
                >
                    <Flex direction={'column'} w={isMobile ? '100%' : 500}>
                        <Title fz={isMobile ? 34 : 65}>
                            How much can{' '}
                            <span
                                style={{
                                    color: Colors.primaryColor,
                                }}
                            >
								you earn?
							</span>{' '}
                        </Title>
                        <Text>
                            Adjust your monthly spend in each category to see how much you
                            could earn with Crazy by Rasel.
                        </Text>
                    </Flex>
                    <Flex direction={'column'} w={isMobile ? '100%' : '450px'} gap={30}>
                        <Flex direction={'column'}>
                            <Flex justify={'space-between'} w={'100%'}>
                                <Text>Fuel</Text>
                                <Text>$60 / month</Text>
                            </Flex>
                            <Box maw={isMobile ? '100%' : 600}>
                                <Slider w={'100%'} value={60} />
                            </Box>
                        </Flex>
                        <Flex direction={'column'}>
                            <Flex justify={'space-between'} w={'100%'}>
                                <Text>Restaurants</Text>
                                <Text>$40 / month</Text>
                            </Flex>
                            <Box maw={isMobile ? '100%' : 600}>
                                <Slider value={40} />
                            </Box>
                        </Flex>
                        <Flex direction={'column'}>
                            <Flex justify={'space-between'} w={'100%'}>
                                <Text>Grocery</Text>
                                <Text>$20 / month</Text>
                            </Flex>
                            <Box maw={isMobile ? '100%' : 600}>
                                <Slider value={20} />
                            </Box>
                        </Flex>
                    </Flex>
                </Flex>

                <Flex
                    w={'100%'}
                    direction={'column'}
                    align={'center'}
                    justify={'center'}
                    gap={30}
                    my={100}
                >
                    <Title fz={isMobile ? 34 : 65}>
                        Get the{' '}
                        <span
                            style={{
                                color: Colors.primaryColor,
                            }}
                        >
							app
						</span>{' '}
                    </Title>
                    <Text ta={'center'}>
                        Download today and unlock your earning potential
                    </Text>
                    <Button color='#AAE104' size='xl' radius={'md'}>
                        {' '}
                        <Image src={playstoreImage} h={30} mr={16} />{' '}
                        <Flex direction={'column'}>
                            <Text c={'black'} fz={'xs'}>
                                Get it on
                            </Text>
                            <Text c={'black'} fz={'md'} fw={'bold'}>
                                Google Play
                            </Text>
                        </Flex>
                    </Button>
                </Flex>
            </Flex>
        </>
    )
}

export default Loyalty
