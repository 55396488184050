import { em, Flex, Loader, Text } from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import {
	getAllProductsAction,
	getAllProductsActionBySubCategory,
} from '../../../store/product/productActions.ts'
import { useAppDispatch, useAppSelector } from '../../../store/store.ts'
import { getCentre } from '../../../utils/storage.ts'
import ProductCardNew from './ProductCardNew.tsx'

const ProductsViewAll = () => {
	const location = useLocation()
	const queryParams = new URLSearchParams(location.search)
	const state = location.state
	const category = queryParams.get('category')
	const dispatch = useAppDispatch()
	const { allProductBySubcategory, products, loaders } = useAppSelector(
		(state) => state.product
	)
	const isSmall = useMediaQuery(`(max-width: ${em(768)})`)
	const [categoryState, setCategoryState] = useState('')

	const centre = getCentre()

	useEffect(() => {
		window.scrollTo({ top: 0, behavior: 'smooth' })
	}, [])

	useEffect(() => {
		if (!category) return
		const decodedCategory = decodeURIComponent(category)
		setCategoryState(decodedCategory)
		if (state === 'viewAll') {
			dispatch(getAllProductsAction(centre))
		}
		dispatch(getAllProductsActionBySubCategory({ category }))
	}, [category, state])

	if (state === 'viewAll') {
		return (
			<>
				{loaders.gettingAllProducts ? (
					<Flex h='100vh' w='100%' align='center' justify='center'>
						<Loader />
					</Flex>
				) : products?.data?.categoryProducts?.length === 0 ? (
					<Flex w='100%' justify='center' align='center' my='xl' py='xl'>
						<Text>No product found in this category</Text>
					</Flex>
				) : (
					<Flex justify='center' align='center'>
						<Flex direction='column' maw={isSmall ? '100%' : 1240}>
							<Flex justify='space-between'>
								<Text fw={700} m='xs'>
									{products?.data?.categoryProducts?.length} Items Related to{' '}
									{categoryState}
								</Text>
							</Flex>
							<Flex wrap='wrap' justify={isSmall ? 'center' : ''} mx='xs'>
								{products?.data?.categoryProducts?.map((product) => {
									if (product.category === categoryState) {
										return product?.products.map((p, index) => {
											return <ProductCardNew key={index} product={p} />
										})
									}
								})}
							</Flex>
						</Flex>
					</Flex>
				)}
			</>
		)
	}
	return (
		<>
			{loaders.gettingAllProductBySubCategory ? (
				<Flex h='100vh' w='100%' align='center' justify='center'>
					<Loader />
				</Flex>
			) : allProductBySubcategory?.products?.length === 0 ? (
				<Flex w='100%' justify='center' align='center' my='xl' py='xl'>
					<Text>No product found in this category</Text>
				</Flex>
			) : (
				<Flex justify='center' align='center'>
					<Flex direction='column' maw={isSmall ? '100%' : 1240}>
						<Flex my='lg' justify='space-between'>
							<Text fw={700} m='xs'>
								{allProductBySubcategory?.products?.length} Items Related to{' '}
								{categoryState}
							</Text>
						</Flex>
						<Flex wrap='wrap' justify={isSmall ? 'center' : ''} mx='xs'>
							{allProductBySubcategory?.products?.map((product, index) => {
								return <ProductCardNew key={index} product={product} />
							})}
						</Flex>
					</Flex>
				</Flex>
			)}
		</>
	)
}

export default ProductsViewAll
