import { Carousel } from '@mantine/carousel'
import { Anchor, em, Flex, Loader, rem, Stack, Text } from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'
import { IconArrowLeft, IconArrowRight } from '@tabler/icons-react'
import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { getAllProductsActionByCategory } from '../../../store/product/productActions.ts'
import { useAppDispatch, useAppSelector } from '../../../store/store.ts'
import { getUser } from '../../../utils/storage.ts'
import classes from '../home/carousel.module.css'
import ProductCardNew from './ProductCardNew.tsx'

const ProductsFilterByCategory = () => {
	const location = useLocation()
	const queryParams = new URLSearchParams(location.search)
	const category = queryParams.get('category')
	const isMobile = useMediaQuery(`(max-width: ${em(768)})`)
	const dispatch = useAppDispatch()
	const { productsWithCategory, loaders } = useAppSelector(
		(state) => state.product
	)
	const navigate = useNavigate()
	const authUser = getUser()

	useEffect(() => {
		if (!category) return
		const decodedCategory = decodeURIComponent(category)
		dispatch(getAllProductsActionByCategory({ category: decodedCategory }))
	}, [category])

	useEffect(() => {
		window.scrollTo({ top: 0, behavior: 'smooth' })
	}, [])

	return (
		<>
			{loaders.gettingAllProductsWithCategory ? (
				<Flex h='100vh' w='100%' align='center' justify='center'>
					<Loader />
				</Flex>
			) : productsWithCategory?.subCategories?.length === 0 ? (
				<Flex w='100%' justify='center' align='center' my='xl' py='xl'>
					<Text>No product found in this category</Text>
				</Flex>
			) : (
				<Flex justify='center' align='center'>
					<Flex
						direction={productsWithCategory?.subCategories ? 'column' : 'row'}
						maw={isMobile ? '100%' : 1240}
					>
						{productsWithCategory?.subCategories
							? productsWithCategory?.subCategories?.map((category, index) => {
									return (
										<>
											<Flex key={index} my='lg' justify='space-between' m='xs'>
												<Text fw={700}>{category?.title}</Text>
												<Anchor
													style={{ color: '#62BE12' }}
													underline='never'
													onClick={() => {
														const param = encodeURIComponent(category?.title)
														navigate(`/view-all-buyer?category=${param}`)
													}}
												>
													View all
												</Anchor>
											</Flex>
											<Carousel
												nextControlIcon={
													<IconArrowRight
														style={{
															width: rem(30),
															height: rem(30),
															boxShadow: '5px 5px 10px 2px rgba(0,0,0,.4)',
															borderRadius: '100px',
														}}
													/>
												}
												previousControlIcon={
													<IconArrowLeft
														style={{
															width: rem(30),
															height: rem(30),
															boxShadow: '5px 5px 10px 2px rgba(0,0,0,.4)',
															borderRadius: '100px',
														}}
													/>
												}
												slideSize={isMobile ? '160px' : '160px'}
												slideGap={0}
												align='start'
												dragFree
												classNames={classes}
											>
												{category?.products?.map((product, index) => {
													return (
														<Carousel.Slide key={index}>
															<ProductCardNew
																product={product}
																ageVerified={authUser?.ageVerification}
															/>
														</Carousel.Slide>
													)
												})}
											</Carousel>
										</>
									)
							  })
							: productsWithCategory?.products?.map((product) => {
									return (
										<Stack>
											<ProductCardNew product={product} />
										</Stack>
									)
							  })}
					</Flex>
				</Flex>
			)}
		</>
	)
}

export default ProductsFilterByCategory
