import { Carousel } from '@mantine/carousel'
import {
	ActionIcon,
	Anchor,
	Avatar,
	Badge,
	Box,
	Breadcrumbs,
	Button,
	Card,
	Divider,
	em,
	Flex,
	Group,
	Image,
	List,
	Paper,
	Rating,
	rem,
	ScrollArea,
	Skeleton,
	Spoiler,
	Text,
} from '@mantine/core'
import { useDisclosure, useMediaQuery } from '@mantine/hooks'
import { modals } from '@mantine/modals'
import {
	IconArrowLeft,
	IconArrowRight,
	IconHeartFilled,
} from '@tabler/icons-react'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import fallbackImage from '../../../assets/Placholder-01.png'
import BuyNowDrawer from '../../../components/drawers/BuyNowDrawer.tsx'
import Footer from '../../../components/Footer.tsx'
import ImageZoom from '../../../components/ImageZoom.tsx'
import BuyNowModal from '../../../components/modals/BuyNowModal.tsx'
import ProductInfoSkeleton from '../../../components/skeletons/ProductInfoSkeleton.tsx'
import { ProductQueryParams } from '../../../interfaces/IQueryParams.ts'
import {
	addToCartAction,
	addToFavouriteAction,
	getOneProduct,
	getProductsSuggestionAction,
	removeFromFavouriteAction,
} from '../../../store/product/productActions.ts'
import { useAppDispatch, useAppSelector } from '../../../store/store.ts'
import { discountCalculatorWithCurrency } from '../../../utils/discountCalculator.ts'
import {
	calculateRatings,
	calculateStars,
} from '../../../utils/ratingCalculator.ts'
import { round } from '../../../utils/roundOffFunction.ts'
import { getUser, getUserId } from '../../../utils/storage.ts'
import classes from '../home/carousel.module.css'
import ProductCardNew from './ProductCardNew.tsx'

const items = [
	{ title: 'Home', href: '/buyer' },
	{ title: 'Product detail', href: '#' },
].map((item, index) => (
	<Anchor c='black' href={item.href} key={index}>
		{item.title}
	</Anchor>
))
const ProductDetailsBuyer = () => {
	const { id } = useParams()
	const dispatch = useAppDispatch()
	const { singleProduct, suggestedProducts, loaders } = useAppSelector(
		(state) => state.product
	)
	const isMedium = useMediaQuery(`(max-width: ${em(992)})`)
	const isSmallLaptop = useMediaQuery(`(max-width: ${em(1024)})`)
	const isSmall = useMediaQuery(`(max-width: ${em(768)})`)
	// const imgs = [mobile1, mobile2, mobile3, mobile4]
	const [pic, setPic] = useState(singleProduct?.product?.images[0])
	const [selected, setSelected] = useState(0)
	const navigate = useNavigate()
	const isMobile = useMediaQuery(`(max-width: ${em(768)})`)
	const userId = getUserId()
	const authUser = getUser()
	const isAgeVerificationRejected = authUser?.ageVerification === 'rejected'
	const isAgeVerificationPending = authUser?.ageVerification === 'pending'
	const [opened, { open, close }] = useDisclosure(false)
	const [favourite, setFavourite] = useState(false)

	useEffect(() => {
		window.scrollTo({ top: 0, behavior: 'smooth' })

		if (!id) return
		dispatch(getOneProduct({ id: id }))
	}, [id])

	useEffect(() => {
		setPic(singleProduct?.product?.images[0])

		if (
			!singleProduct?.product?._id &&
			!singleProduct?.product?.businessDetails?.businessId
		)
			return
		const params: ProductQueryParams = {
			productId: singleProduct?.product?._id,
			productType: singleProduct?.product?.productType,
			category: singleProduct?.product?.category[0],
			businessId: singleProduct?.product?.businessDetails?.businessId,
		}
		dispatch(getProductsSuggestionAction(params))
	}, [singleProduct])

	return (
		<>
			<Flex justify='center'>
				<Paper
					px={isSmall ? 'sm' : 'xl'}
					pb={isSmall ? 70 : 'sm'}
					maw={isSmall ? '100%' : isSmallLaptop ? '90%' : '70%'}
				>
					<Breadcrumbs separator='>' separatorMargin='md' mt='xs'>
						{items}
					</Breadcrumbs>
					<Flex w='100%' direction={isMedium ? 'column' : 'row'} gap='xl'>
						<Card w={isSmall ? '100%' : isMedium ? '100%' : '30%'} p={0}>
							<Flex direction='column' h='100%' py={isSmall ? 'sm' : 'xl'}>
								{loaders.gettingOneProduct ? (
									<Skeleton height={400} width='100%' />
								) : (
									<ImageZoom src={pic} alt='product image' />
								)}
								{/*<Image src={pic} h="400px"*/}
								{/*       fallbackSrc={fallbackImage}/>*/}
								<ScrollArea type={isSmall ? 'never' : 'auto'}>
									<Flex gap={12} my='lg' h='100px'>
										{loaders.gettingOneProduct
											? [1, 2, 3, 4].map((_, index) => (
													<Card key={index} p={0} color='blue'>
														<Skeleton h='100%' w='100px' radius='xs' />
													</Card>
											  ))
											: singleProduct?.product?.images.map((img, index) => {
													return (
														<Card
															key={index}
															style={{
																cursor: 'pointer',
																border:
																	selected === index ? '1px solid #62BE12' : '',
															}}
															p={0}
															color='blue'
														>
															<Image
																onClick={() => {
																	setPic(img)
																	setSelected(index)
																}}
																fallbackSrc={fallbackImage}
																style={{
																	border: '1px blue',
																	borderBlock: 'blue',
																}}
																key={index}
																src={img}
																h='100%'
																w='100px'
																radius='xs'
															/>
														</Card>
													)
											  })}
									</Flex>
								</ScrollArea>
							</Flex>
						</Card>
						<Flex
							direction='column'
							py={isSmall ? '' : 'xl'}
							h='100%'
							gap='lg'
							w={isMedium ? '100%' : '50%'}
							mx={isSmall ? '' : 'xl'}
						>
							{loaders.gettingOneProduct ? (
								<Skeleton height={20} width='100%' />
							) : (
								<Text size={isSmall ? '20px' : '32px'} fw={700}>
									{singleProduct?.product?.productName}
								</Text>
							)}
							<Group>
								{loaders.gettingOneProduct ? (
									<Skeleton height={20} width='200px' />
								) : (
									singleProduct?.product?.category?.map((category, index) => (
										<Badge
											key={index}
											variant='light'
											color='gray'
											size='lg'
											c='#62BE12'
										>
											#{category}
										</Badge>
									))
								)}
								{loaders.gettingOneProduct ? (
									<Skeleton height={20} width='200px' />
								) : (
									singleProduct?.product?.subCategory?.map(
										(category, index) => (
											<Badge
												key={index}
												variant='light'
												color='gray'
												size='lg'
												c='#62BE12'
											>
												#{category}
											</Badge>
										)
									)
								)}
							</Group>
							<Group>
								{loaders.gettingOneProduct ? (
									<Skeleton height={20} width='100%' />
								) : (
									<>
										<span
											style={{
												fontSize: isSmall ? '24px' : '32px',
												color:
													singleProduct?.product?.discount > 0
														? 'black'
														: '#A8AEBF',
												fontWeight:
													singleProduct?.product?.discount > 0 ? 600 : 10,
											}}
										>
											{discountCalculatorWithCurrency(
												singleProduct?.product?.productPrice?.amount,
												singleProduct?.product?.discount,
												singleProduct?.product?.productPrice?.currency
											)}
										</span>
										<Text
											fw={singleProduct?.product?.discount > 0 ? 10 : 600}
											td={
												singleProduct?.product?.discount > 0
													? 'line-through'
													: ''
											}
										>
											${round(singleProduct?.product?.productPrice?.amount)}
										</Text>

										<span style={{ color: '#62BE12' }}>
											{singleProduct?.product?.discount}% off
										</span>
										<ActionIcon
											variant='subtle'
											color='#E0E0E0'
											onClick={(e) => {
												e.stopPropagation()
												// setID(product?._id)
												if (
													singleProduct?.product?.favoriteOf?.includes(userId)
												) {
													dispatch(
														removeFromFavouriteAction({
															productId: singleProduct?.product?._id,
														})
													)
												} else {
													dispatch(
														addToFavouriteAction({
															productId: singleProduct?.product?._id,
														})
													).then(() => {
														setFavourite(true)
													})
												}
											}}
											radius='xl'
										>
											<IconHeartFilled
												color='blue'
												style={{
													color:
														singleProduct?.product?.favoriteOf?.includes(
															userId
														) || favourite
															? '#62BE12'
															: '',
												}}
											/>
										</ActionIcon>
									</>
								)}
							</Group>

							<Flex justify='space-between' w='100%'>
								<Flex
									bg='white'
									w={isSmall ? '100%' : '100%'}
									direction='column'
									gap={12}
									py='lg'
								>
									<Box>
										<Text fw={600}>Description</Text>
										{loaders.gettingOneProduct ? (
											<Skeleton height={60} width='100%' />
										) : (
											<Spoiler
												maxHeight={300}
												showLabel='Show more'
												hideLabel='Hide'
											>
												<Text size='sm' c='#474C59'>
													{singleProduct?.product?.description}
												</Text>
											</Spoiler>
										)}
									</Box>
									<Box>
										<Text fw={600}>Highlights</Text>
										{loaders.gettingOneProduct ? (
											<Skeleton height={60} width='100%' />
										) : (
											<List mx='lg' size='sm' c='#474C59'>
												{singleProduct?.product?.highlights.map(
													(item, index) => {
														return <List.Item key={index}>{item}</List.Item>
													}
												)}
											</List>
										)}
									</Box>
								</Flex>
							</Flex>
							{!isSmall && (
								<Flex my='lg' w='100%' justify='space-evenly'>
									<Button
										component='a'
										fw={100}
										size='lg'
										radius='xl'
										color='#62BE12'
										onClick={() => {
											if (
												singleProduct?.product?.category?.includes('Alcohol') ||
												singleProduct?.product?.category?.includes('Smoke')
											) {
												if (isAgeVerificationRejected) {
													navigate('/age-verification')
													return
												} else if (isAgeVerificationPending) {
													modals.open({
														title: 'Age Verification Pending',
														centered: true,
														// confirmProps: {},
														children: (
															<Flex direction='column' gap='sm'>
																<Flex w='100%' justify='center'>
																	<Avatar
																		size={isMobile ? '150px' : '200px'}
																		radius='xs'
																		src='https://res.cloudinary.com/dhl8hhlsx/image/upload/v1726034777/Icons/epr04dscthif0gtasqib.png'
																	/>
																</Flex>
																<Text ta='center' size={isMobile ? 'md' : 'lg'}>
																	Your Account is under consideration for Age
																	Verification
																</Text>
																<Group justify='center'>
																	<Button
																		fw={200}
																		radius='xl'
																		w='30%'
																		onClick={() => modals.closeAll()}
																	>
																		Okay
																	</Button>
																</Group>
															</Flex>
														),
													})
													return
												}
											}
											open()
										}}
										w='40%'
									>
										Buy Now
									</Button>
									<Button
										component='a'
										fw={200}
										size='lg'
										radius='xl'
										onClick={() => {
											if (
												singleProduct?.product?.category?.includes('Alcohol') ||
												singleProduct?.product?.category?.includes('Smoke')
											) {
												if (isAgeVerificationRejected) {
													navigate('/age-verification')
													return
												} else if (isAgeVerificationPending) {
													modals.open({
														centered: true,
														children: (
															<Flex direction='column' gap='sm'>
																<Flex w='100%' justify='center'>
																	<Avatar
																		size={isMobile ? '150px' : '200px'}
																		radius='xs'
																		src='https://res.cloudinary.com/dhl8hhlsx/image/upload/v1726034777/Icons/epr04dscthif0gtasqib.png'
																	/>
																</Flex>
																<Text ta='center' size={isMobile ? 'md' : 'lg'}>
																	Your Account is under consideration for Age
																	Verification
																</Text>
																<Group justify='center'>
																	<Button
																		fw={200}
																		radius='xl'
																		w='30%'
																		onClick={() => modals.closeAll()}
																	>
																		Okay
																	</Button>
																</Group>
															</Flex>
														),
													})
													return
												}
											}
											dispatch(
												addToCartAction({
													productId: singleProduct?.product?._id,
												})
											)
										}}
										color='#62BE12'
										w='40%'
										variant='outline'
									>
										Add To Cart
									</Button>
								</Flex>
							)}
						</Flex>
					</Flex>

					<Divider />
					<Flex w='100%' my='lg' direction='column' gap='sm'>
						<Text fw={700} size={isSmall ? '20px' : '32px'}>
							Product Ratings & Reviews
						</Text>
						<Flex
							my={'lg'}
							justify='space-between'
							gap={isSmall ? 'lg' : ''}
							direction={isSmall ? 'column' : 'row'}
						>
							<Flex w={'100%'} direction='column' gap='xl'>
								<Flex gap='xl' direction={isSmall ? 'column' : 'row'}>
									{/*<Badge variant="light" color="gray"*/}
									{/*       size="lg">{calculateRatings(product?.product?.rating.totalRating, product?.product?.rating.numRating)}/5</Badge>*/}

									<Flex
										direction='column'
										gap='xs'
										className={classes.review}
										p={'20px'}
										w={isSmall ? '100%' : '25%'}
										h={'max-content'}
									>
										{/*<span style={{color: "#767C8C"}}>Overall Rating</span>*/}
										{/* <Text>
											{singleProduct?.product?.rating?.numRating} Ratings
										</Text> */}
										<Text fz={'h5'}>Overall Rating</Text>
										<Group>
											{loaders.gettingOneProduct ? (
												<Skeleton height={60} width='100%' />
											) : (
												<Text fz={'h1'}>
													{calculateRatings(
														singleProduct?.product?.rating?.totalRating ?? 0,
														singleProduct?.product?.rating?.numRating ?? 0
													)}{' '}
													<Text fz={'h1'} opacity={0.5} component='span'>
														({singleProduct?.product?.rating?.numRating ?? 0})
													</Text>
												</Text>
											)}
										</Group>
										{loaders.gettingOneProduct ? (
											<Skeleton height={20} width='100%' />
										) : (
											<Rating
												fractions={4}
												readOnly
												size='lg'
												value={calculateStars(
													singleProduct?.product?.rating.totalRating,
													singleProduct?.product?.rating.numRating
												)}
											/>
										)}
									</Flex>
									<Flex
										p={'20px'}
										direction={'column'}
										style={{
											flex: 1,
										}}
										className={classes.review}
										w={'100%'}
									>
										<Text fz={'md'} mb={20}>
											{singleProduct?.product?.reviews?.length} Customer Reviews
										</Text>
										<Flex w={'100%'} direction={'row'} wrap={'wrap'} gap={10}>
											{singleProduct?.product?.reviews.map((review, index) => {
												return (
													<Paper
														key={index}
														withBorder
														radius='md'
														className={classes.comment}
														w={'100%'}
													>
														<Group>
															<Avatar
																src={review?.userInfo?.profilePic}
																alt={review?.userInfo?.firstName}
																radius='xl'
															/>
															<div>
																<Text fz='sm'>
																	{review?.userInfo?.firstName}{' '}
																	{review?.userInfo?.lastName}
																</Text>
																<Text fz='xs' c='dimmed'>
																	{new Date(
																		review?.createdAt
																	).toLocaleDateString()}
																</Text>
															</div>
														</Group>
														<Rating my={20} value={review?.rating} readOnly />
														<Text mt={20}>{review?.review}</Text>
													</Paper>
												)
											})}
										</Flex>
										<Button
											w={isSmall ? '100%' : 'max-content'}
											variant='outline'
											radius={'xl'}
											mt={20}
											size='md'
											mx={'auto'}
										>
											See All Reviews ({singleProduct?.product?.reviews?.length}
											)
										</Button>
									</Flex>
								</Flex>
								{/*{loaders.gettingOneProduct ? <Skeleton height={10} width="100%"/> :*/}
								{/*    <Text>{singleProduct?.product?.rating?.totalRating} reviews</Text>}*/}
							</Flex>
							{/*<Button variant="outline" color="#62BE12" w="20%">Rate</Button>*/}
						</Flex>
						<Divider />
						<Flex direction='column' gap='sm'>
							<Flex direction='column' gap='sm' bg='#F6F6F6' p='sm'>
								<Text fw={600}>Meet your seller</Text>
								<Flex align='center' gap='lg' justify='space-between'>
									<Flex align='center' gap='sm'>
										{loaders.gettingOneProduct ? (
											<Skeleton height={50} circle width='100%' />
										) : (
											<Avatar
												size='lg'
												src={
													singleProduct?.product?.businessDetails?.businessImage
												}
											/>
										)}
										{loaders.gettingOneProduct ? (
											<Skeleton height={60} width='100%' />
										) : (
											<Text fw={600}>
												{singleProduct?.product?.businessDetails?.businessTitle}
											</Text>
										)}
									</Flex>
									<Button
										radius='xl'
										fw={200}
										onClick={() => {
											navigate(
												`/chat/${singleProduct?.product?.sellerId}?name=${singleProduct?.product?.businessDetails?.businessTitle}&avatar=${singleProduct?.product?.businessDetails?.businessImage}`
											)
										}}
									>
										Chat with seller
									</Button>
									{/*<ActionIcon onClick={() => {*/}
									{/*    navigate(`/chat/${singleProduct?.product?.sellerId}?name=${singleProduct?.product?.businessDetails?.businessTitle}&avatar=${singleProduct?.product?.businessDetails?.businessImage}`)*/}
									{/*}} radius="xl" size="lg" variant="outline" color="#F5F6FA"*/}
									{/*            c="#62BE12">*/}
									{/*    <IconMessageDots size={15}/>*/}
									{/*</ActionIcon>*/}
								</Flex>
							</Flex>
							<Divider />
							<Flex justify='space-between'>
								<Text fw={700}>From this shop</Text>
							</Flex>
							<Carousel
								nextControlIcon={
									<IconArrowRight
										style={{
											width: rem(30),
											height: rem(30),
											boxShadow: '5px 5px 10px 2px rgba(0,0,0,.4)',
											borderRadius: '100px',
										}}
									/>
								}
								previousControlIcon={
									<IconArrowLeft
										style={{
											width: rem(30),
											height: rem(30),
											boxShadow: '5px 5px 10px 2px rgba(0,0,0,.4)',
											borderRadius: '100px',
										}}
									/>
								}
								slideSize={isMobile ? '160px' : '160px'}
								slideGap={0}
								align='start'
								dragFree
								classNames={classes}
								// p="xs"
							>
								{loaders.gettingOneProduct
									? [1, 2, 3, 4, 5, 6, 8, 9, 10, 11, 22, 33].map((_, index) => (
											<Carousel.Slide key={index}>
												<ProductInfoSkeleton />
											</Carousel.Slide>
									  ))
									: suggestedProducts?.productsFromSameShop?.map(
											(product, index) => {
												return (
													<Carousel.Slide key={index}>
														<ProductCardNew
															favourite={product?.favoriteOf?.includes(userId)}
															product={product}
														/>
													</Carousel.Slide>
												)
											}
									  )}
							</Carousel>
							<Flex justify='space-between'>
								<Text fw={700}>Similar products</Text>
							</Flex>
							<Carousel
								nextControlIcon={
									<IconArrowRight
										style={{
											width: rem(30),
											height: rem(30),
											boxShadow: '5px 5px 10px 2px rgba(0,0,0,.4)',
											borderRadius: '100px',
										}}
									/>
								}
								previousControlIcon={
									<IconArrowLeft
										style={{
											width: rem(30),
											height: rem(30),
											boxShadow: '5px 5px 10px 2px rgba(0,0,0,.4)',
											borderRadius: '100px',
										}}
									/>
								}
								slideSize={isMobile ? '160px' : '160px'}
								slideGap={0}
								align='start'
								dragFree
								classNames={classes}
								// p="xs"
							>
								{loaders.gettingOneProduct
									? [1, 2, 3, 4, 5, 6, 8, 9, 10, 11, 22, 33].map((_, index) => (
											<Carousel.Slide key={index}>
												<ProductInfoSkeleton />
											</Carousel.Slide>
									  ))
									: suggestedProducts?.similarProducts?.map(
											(product, index) => {
												return (
													<Carousel.Slide key={index}>
														<ProductCardNew
															favourite={product?.favoriteOf?.includes(userId)}
															product={product}
														/>
													</Carousel.Slide>
												)
											}
									  )}
							</Carousel>

							<Flex justify='space-between'>
								<Text fw={700}>You might also like</Text>
							</Flex>
							<Carousel
								nextControlIcon={
									<IconArrowRight
										style={{
											width: rem(30),
											height: rem(30),
											boxShadow: '5px 5px 10px 2px rgba(0,0,0,.4)',
											borderRadius: '100px',
										}}
									/>
								}
								previousControlIcon={
									<IconArrowLeft
										style={{
											width: rem(30),
											height: rem(30),
											boxShadow: '5px 5px 10px 2px rgba(0,0,0,.4)',
											borderRadius: '100px',
										}}
									/>
								}
								slideSize={isMobile ? '160px' : '160px'}
								slideGap={0}
								align='start'
								dragFree
								classNames={classes}
								// p="xs"
							>
								{loaders.gettingOneProduct
									? [1, 2, 3, 4, 5, 6, 8, 9, 10, 11, 22, 33].map((_, index) => (
											<Carousel.Slide key={index}>
												<ProductInfoSkeleton />
											</Carousel.Slide>
									  ))
									: suggestedProducts?.youMightAlsoLike?.map(
											(product, index) => {
												return (
													<Carousel.Slide key={index}>
														<ProductCardNew
															favourite={product?.favoriteOf?.includes(userId)}
															product={product}
														/>
													</Carousel.Slide>
												)
											}
									  )}
							</Carousel>
						</Flex>
					</Flex>
				</Paper>
			</Flex>
			<Footer />
			{isSmall && (
				<Flex
					pos='fixed'
					bottom={0}
					w={isSmall ? '100%' : '50%'}
					justify='space-evenly'
					bg='white'
					py='sm'
				>
					<Button
						radius='xl'
						size='xxl'
						fw={200}
						color='#62BE12'
						onClick={() => {
							if (
								singleProduct?.product?.category?.includes('Alcohol') ||
								singleProduct?.product?.category?.includes('Smoke Essentials')
							) {
								if (isAgeVerificationRejected) {
									navigate('/age-verification')
									return
								} else if (isAgeVerificationPending) {
									modals.open({
										title: 'Age Verification Pending',
										centered: true,
										// confirmProps: {},
										children: (
											<Flex direction='column' gap='sm'>
												<Flex w='100%' justify='center'>
													<Avatar
														size={isMobile ? '150px' : '200px'}
														radius='xs'
														src='https://res.cloudinary.com/dhl8hhlsx/image/upload/v1726034777/Icons/epr04dscthif0gtasqib.png'
													/>
												</Flex>
												<Text ta='center' size={isMobile ? 'md' : 'lg'}>
													Your Account is under consideration for Age
													Verification
												</Text>
												<Group justify='center'>
													<Button
														fw={200}
														radius='xl'
														w='30%'
														onClick={() => modals.closeAll()}
													>
														Okay
													</Button>
												</Group>
											</Flex>
										),
									})
									return
								}
							}
							open()
						}}
						w='40%'
					>
						Buy Now
					</Button>
					<Button
						radius='xl'
						size='xxl'
						fw={200}
						onClick={() => {
							if (
								singleProduct?.product?.category?.includes('Alcohol') ||
								singleProduct?.product?.category?.includes('Smoke Essentials')
							) {
								if (isAgeVerificationRejected) {
									navigate('/age-verification')
									return
								} else if (isAgeVerificationPending) {
									modals.open({
										title: 'Age Verification Pending',
										centered: true,
										// confirmProps: {},
										children: (
											<Flex direction='column' gap='sm'>
												<Flex w='100%' justify='center'>
													<Avatar
														size={isMobile ? '150px' : '200px'}
														radius='xs'
														src='https://res.cloudinary.com/dhl8hhlsx/image/upload/v1726034777/Icons/epr04dscthif0gtasqib.png'
													/>
												</Flex>
												<Text ta='center' size={isMobile ? 'md' : 'lg'}>
													Your Account is under consideration for Age
													Verification
												</Text>
												<Group justify='center'>
													<Button
														fw={200}
														radius='xl'
														w='30%'
														onClick={() => modals.closeAll()}
													>
														Okay
													</Button>
												</Group>
											</Flex>
										),
									})
									return
								}
							}
							dispatch(
								addToCartAction({ productId: singleProduct?.product?._id })
							)
						}}
						color='#62BE12'
						w='40%'
						variant='outline'
					>
						Add To Cart
					</Button>
				</Flex>
			)}
			{isMobile ? (
				<BuyNowDrawer
					opened={opened}
					onClose={close}
					product={singleProduct?.product}
				/>
			) : (
				<BuyNowModal
					opened={opened}
					onClose={close}
					product={singleProduct.product}
				/>
			)}
		</>
	)
}

export default ProductDetailsBuyer
