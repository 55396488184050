import { createAsyncThunk } from '@reduxjs/toolkit'
import { AxiosError } from 'axios'
import { ISearchProducts } from '../../components/CustomMultiSelect.tsx'
import axiosInstance from '../../config/axios.config.ts'
import { IAddToCartResponse } from '../../interfaces/buyer/cart/IAddToCartResponse.ts'
import {
	ICartUpdateData,
	Item,
} from '../../interfaces/buyer/cart/ICartUpdateData.ts'
import { ICouponsResponse } from '../../interfaces/buyer/cart/ICouponsResponse.ts'
import {
	IBuyNowCreateOrder,
	ICreateOrder,
} from '../../interfaces/buyer/cart/ICreateOrder.ts'
import { IDeleteCartItemResponse } from '../../interfaces/buyer/cart/IDeleteCartItemResponse.ts'
import { IUpdateCartResponseNew } from '../../interfaces/buyer/cart/IUpdateCartResponseNew.ts'
import { IUpdatedCartResponse } from '../../interfaces/buyer/cart/IUpdatetdCartResponse.ts'
import { IAddProductToWishlist } from '../../interfaces/buyer/product/IAddProductToWishList.ts'
import { IProductByCategoryResponse } from '../../interfaces/buyer/product/IProductByCategoryResponse.ts'
import { IProductBySubCategoryResponse } from '../../interfaces/buyer/product/IProductBySubCategory.ts'
import { IRecentSearches } from '../../interfaces/buyer/product/IRecentSearches.ts'
import { IReviewProductSubmit } from '../../interfaces/buyer/product/IReviewProductSubmit.ts'
import { ISingleProductResponse } from '../../interfaces/buyer/product/ISingleProductResponse.ts'
import { ISuggestedProductsResponse } from '../../interfaces/buyer/product/ISuggestedProductsResponse.ts'
import { IUnReviewedProductsResponse } from '../../interfaces/buyer/product/IUnReviewedProductsResponse.ts'
import { IGuestSingleProduct } from '../../interfaces/guest/IGuestSingleProduct.ts'
import { ProductQueryParams } from '../../interfaces/IQueryParams.ts'
import { errorHandler } from '../../utils/errorHandler.ts'
import { notificationSystem } from '../../utils/notificationSystem.ts'
import { toggleCartAction, updateCart } from './productSlice.ts'

export const getAllProductsAction = createAsyncThunk(
	'product/getAllProducts',
	async ({ lat, lng }: { lat: number; lng: number }) => {
		try {
			const res = await axiosInstance.get(
				`buyer/home-init-data?lng=${lng}&lat=${lat}`
			)

			return res.data
		} catch (e) {
			const error = e as AxiosError
			errorHandler(error)
			console.error(e)
		}
	}
)
export const getAllGuestProductsAction = createAsyncThunk(
	'product/getAllGuestProducts',
	async () => {
		try {
			const res = await axiosInstance.get('guest/home-data')
			if (!res.data.success) {
				notificationSystem('Something went wrong', res.data.message, true)
			}
			return res.data
		} catch (e) {
			const error = e as AxiosError
			errorHandler(error)
			console.error(e)
		}
	}
)
export const getOneGuestProductAction = createAsyncThunk(
	'product/getOneGuestProducts',
	async (id: string) => {
		try {
			const res = await axiosInstance.get<IGuestSingleProduct>(
				`guest/guest-product-by-id?productId=${id}`
			)

			if (!res.data.success) {
				notificationSystem('Something went wrong', '', true)
			}
			return res.data
		} catch (e) {
			const error = e as AxiosError
			errorHandler(error)
			console.error(e)
		}
	}
)
export const getOneProduct = createAsyncThunk(
	'product/getOneProduct',
	async ({ id }: { id: string }) => {
		try {
			const res = await axiosInstance.get<ISingleProductResponse>(
				`buyer/product-by-id?productId=${id}`
			)
			if (!res.data.success) {
				notificationSystem('Something went wrong', '', true)
			}
			return res.data
		} catch (e) {
			console.log(e)
			notificationSystem('Something went wrong', '', true)
		}
	}
)
export const addToCartAction = createAsyncThunk(
	'product/addToCart',
	async (
		data: {
			productId: string
		},
		{ dispatch }
	) => {
		try {
			const res = await axiosInstance.post<IAddToCartResponse>(
				'buyer/add-to-cart',
				data
			)

			if (res.data.success) {
				notificationSystem('Item added into cart successfully', '', false)
				dispatch(toggleCartAction(res.data.cart.items.length))
			}
			return res.data
		} catch (e) {
			const error = e as AxiosError
			errorHandler(error)
			console.error(e)
		}
	}
)
export const updateCartAction = createAsyncThunk(
	'product/updateCart',
	async (data: ICartUpdateData, { dispatch }) => {
		try {
			const res = await axiosInstance.put<IUpdatedCartResponse>(
				'buyer/cart',
				data
			)
			dispatch(updateCart(res.data))
			return res.data
		} catch (e) {
			console.log(e)
			notificationSystem('Something went wrong', '', true)
		}
	}
)
export const updateCartItemAction = createAsyncThunk(
	'product/updateCartItem',
	async (
		data: {
			items: Item[]
		},
		{ dispatch }
	) => {
		try {
			const res = await axiosInstance.put<IUpdateCartResponseNew>(
				'buyer/cart-update',
				data
			)
			dispatch(toggleCartAction(res.data.cart.items.length))
			return res.data
		} catch (e) {
			console.log(e)
			notificationSystem('Something went wrong', '', true)
		}
	}
)
export const deleteCartItemAction = createAsyncThunk(
	'product/deleteCartItem',
	async (data: { productId: string }) => {
		try {
			const res = await axiosInstance.put<IDeleteCartItemResponse>(
				'buyer/delete-specific-cart-item',
				data
			)
			if (res.data.success) {
				notificationSystem('Item deleted from cart successfully', '', true)
			}
			return res.data
		} catch (e) {
			console.log(e)
			notificationSystem('Something went wrong', '', true)
		}
	}
)
export const deleteCartAction = createAsyncThunk(
	'product/deleteCart',
	async () => {
		try {
			const res = await axiosInstance.delete('buyer/cart-delete')
			if (!res.data.success) {
				notificationSystem('Something went wrong', res.data.message, true)
			} else {
				notificationSystem('Cart deleted', res.data.message, true)
			}
			return res.data
		} catch (e) {
			console.log(e)
			notificationSystem('Something went wrong', '', true)
		}
	}
)
export const getAllCartItems = createAsyncThunk(
	'product/getAllCartItems',
	async (_, { dispatch }) => {
		try {
			const res = await axiosInstance.get<IUpdatedCartResponse>('buyer/cart')

			dispatch(toggleCartAction(res.data.cart.totalCount))
			return res.data
		} catch (e) {
			const error = e as AxiosError
			errorHandler(error)
			console.error(e)
		}
	}
)
export const getAllProductsActionByCategory = createAsyncThunk(
	'product/getAllProductsByCategory',
	async ({ category }: { category: string }) => {
		try {
			const res = await axiosInstance.get<IProductByCategoryResponse>(
				`buyer/sub-categories-by-category-name?categoryName=${encodeURIComponent(
					category
				)}`
			)

			return res.data
		} catch (e) {
			const error = e as AxiosError
			errorHandler(error)
			console.error(e)
		}
	}
)
export const getAllProductsActionBySubCategory = createAsyncThunk(
	'product/getAllProductsBySubCategory',
	async ({ category }: { category: string }) => {
		try {
			const res = await axiosInstance.get<IProductBySubCategoryResponse>(
				`buyer/products-specific-subcategory?subCategoryName=${encodeURIComponent(
					category
				)}`
			)

			return res.data
		} catch (e) {
			const error = e as AxiosError
			errorHandler(error)
			console.error(e)
		}
	}
)
export const getProductsSuggestionAction = createAsyncThunk(
	'product/getProductsSuggestion',
	async (params: ProductQueryParams) => {
		try {
			const res = await axiosInstance.get<ISuggestedProductsResponse>(
				`buyer/products-suggestion?productId=${params.productId}&businessId=${
					params.businessId
				}&category=${encodeURIComponent(params.category)}&productType=${
					params.productType
				}`
			)

			return res.data
		} catch (e) {
			const error = e as AxiosError
			errorHandler(error)
			console.error(e)
		}
	}
)

export const getAllGuestProductsActionByCategory = createAsyncThunk(
	'product/getAllGuestProductsByCategory',
	async ({ category }: { category: string }) => {
		try {
			const res = await axiosInstance.get(
				`guest/products-specific-category?categoryName=${category}`
			)

			return res.data
		} catch (e) {
			const error = e as AxiosError
			errorHandler(error)
			console.error(e)
		}
	}
)
export const getAllFavouriteProductsAction = createAsyncThunk(
	'product/getAllFavouriteProducts',
	async () => {
		try {
			const res = await axiosInstance.get(
				'buyer/wishlist-products-specific-user'
			)

			return res.data
		} catch (e) {
			const error = e as AxiosError
			errorHandler(error)
			console.error(e)
		}
	}
)
export const searchProductsAction = createAsyncThunk(
	'product/searchProducts',
	async (product: string) => {
		try {
			const res = await axiosInstance.get<ISearchProducts>(
				`buyer/search-product?productName=${product}`
			)

			return res.data
		} catch (e) {
			const error = e as AxiosError
			errorHandler(error)
			console.error(e)
		}
	}
)

export const getRecentSearchesAction = createAsyncThunk(
	'product/getRecentSearches',
	async (type?: string) => {
		try {
			const res = await axiosInstance.get<IRecentSearches>(
				`buyer/get-search-history?type=${type}`
			)
			return res.data
		} catch (e) {
			const error = e as AxiosError
			if (error?.response?.status === 401) {
				localStorage.clear()
			}
			console.error(e)
		}
	}
)

export const createOrderAction = createAsyncThunk(
	'product/createOrder',
	async (data: ICreateOrder) => {
		try {
			const res = await axiosInstance.post('buyer/create-order/v2', data)

			if (res.data.success) {
				notificationSystem('Order has been placed', res.data.message, false)
			}
			return res.data
		} catch (e) {
			const error = e as AxiosError
			errorHandler(error)
			console.error(e)
		}
	}
)
export const createBuyNowOrderAction = createAsyncThunk(
	'product/createBuyNoeOrder',
	async (data: IBuyNowCreateOrder) => {
		try {
			const res = await axiosInstance.post('buyer/buy', data)

			if (res.data.success) {
				notificationSystem('Order has been placed', res.data.message, false)
			}
			return res.data
		} catch (e) {
			const error = e as AxiosError
			errorHandler(error)
			console.error(e)
		}
	}
)

export const addToFavouriteAction = createAsyncThunk(
	'product/addToFavourite',
	async (data: { productId: string }) => {
		try {
			const res = await axiosInstance.put<IAddProductToWishlist>(
				'buyer/buyer-add-wishlist',
				data
			)
			if (res.data.success) {
				notificationSystem('Product has been added to wishlist', '', false)
			}
			return res.data
		} catch (e) {
			const error = e as AxiosError
			errorHandler(error)
			console.error(e)
		}
	}
)

export const removeFromFavouriteAction = createAsyncThunk(
	'product/removeFromFavourite',
	async (data: { productId: string }) => {
		try {
			const res = await axiosInstance.put<IAddProductToWishlist>(
				'buyer/buyer-remove-wishlist',
				data
			)

			if (!res.data.success) {
				notificationSystem('Something went wrong', '', true)
			} else {
				notificationSystem('Product has been removed from wishlist', '', false)
			}
			return res.data
		} catch (e) {
			const error = e as AxiosError
			errorHandler(error)
			console.error(e)
		}
	}
)

export const getAllCoupons = createAsyncThunk(
	'product/getAllCoupons',
	async () => {
		try {
			const res = await axiosInstance.get<ICouponsResponse>(
				'buyer/coupons-with-status'
			)
			return res.data
		} catch (e) {
			const error = e as AxiosError
			errorHandler(error)
			console.error(e)
		}
	}
)

export const getAllUnreviewedProductsAction = createAsyncThunk(
	'product/getAllUnreviewedProducts',
	async () => {
		try {
			const res = await axiosInstance.get<IUnReviewedProductsResponse>(
				'buyer/unreviewed-products'
			)
			return res.data
		} catch (e) {
			const error = e as AxiosError
			errorHandler(error)
			console.error(e)
		}
	}
)

export const submitReviewAction = createAsyncThunk(
	'product/submitReview',
	async (form: IReviewProductSubmit) => {
		try {
			const res = await axiosInstance.post(
				'productReview/single-product-review',
				form
			)
			return res.data
		} catch (e) {
			notificationSystem('Something went wrong', '', true)
		}
	}
)

// export const getSearchHistory = createAsyncThunk(
// 	'buyer/searchProductHistory',
// 	async () => {
// 		try {
// 			const res = await axiosInstance.get<ISearchHistory>(
// 				`buyer/get-search-history`
// 			)
// 			return res.data
// 		} catch (e) {
// 			const error = e as AxiosError
// 			if (error?.response?.status === 401) {
// 				localStorage.clear()
// 			}
// 			console.error(e)
// 		}
// 	}
// )

export const clearSearchHistory = createAsyncThunk(
	'buyer/clearHistory',
	async () => {
		try {
			const res = await axiosInstance.patch<{ success: boolean }>(
				`buyer/clear-search-history`
			)
			return res.data
		} catch (e) {
			const error = e as AxiosError
			if (error?.response?.status === 401) {
				localStorage.clear()
			}
			console.error(e)
		}
	}
)

export const contactUsAction = createAsyncThunk(
	'buyer/contactUs',
	async (data: URLSearchParams) => {
		try {
			const res = await axiosInstance.post<{
				success: boolean
				message: string
			}>(`guest/contact-us`, data)
			if (res.data.success) {
				notificationSystem('Thank You for Contacting Us!', 'We will respond to your message as soon as possible.', false)
			} else notificationSystem(res.data.message, '', true)
		} catch (error) {
			console.log(error)
		}
	}
)
