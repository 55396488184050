import {
	Button,
	em,
	Flex,
	Pagination,
	Paper,
	SimpleGrid,
	Text,
} from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'
import { Fragment, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import OrderCard from '../../../../components/OrderCard.tsx'
import { getAllOrdersAction } from '../../../../store/oders/ordersActions.ts'
import { useAppDispatch, useAppSelector } from '../../../../store/store.ts'
import OrdersTable from '../../../seller/orders/OrdersTable.tsx'

const MyOrders = () => {
	const isMobile = useMediaQuery(`(max-width: ${em(768)})`)
	const dispatch = useAppDispatch()
	const navigate = useNavigate()

	const { orders, loaders } = useAppSelector((state) => state.orders)
	const handlePage = (page: number) => {
		dispatch(getAllOrdersAction(page))
		window.scrollTo({ top: 0, behavior: 'smooth' })
	}

	useEffect(() => {
		dispatch(getAllOrdersAction(1))
	}, [])

	return (
		<Flex direction='column' justify='center' align='center'>
			<Flex direction='column' w={isMobile ? '100%' : 1240} p='md'>
				<Flex w='100%' justify='space-between' align='center'>
					<Text fw={700} size='24px' my='lg'>
						My Orders
					</Text>
					<Button
						fw={200}
						radius='xl'
						onClick={() => navigate('/review-products')}
					>
						To Review
					</Button>
				</Flex>
				<Paper>
					{isMobile ? (
						<SimpleGrid
							cols={{ base: 1, sm: 2, lg: 2 }}
							spacing={{ base: 10, sm: 'xl' }}
							verticalSpacing={{ base: 'md', sm: 'xl' }}
							mt='sm'
						>
							{orders?.orders?.map((order, i) => {
								return (
									<Fragment key={i}>
										<OrderCard
											order={order}
											isBuyer={true}
											loading={loaders.gettingOrders}
										/>
									</Fragment>
								)
							})}
						</SimpleGrid>
					) : (
						<OrdersTable
							orders={orders?.orders}
							isBuyer={true}
							loading={loaders.gettingOrders}
						/>
					)}
					<Pagination
						color='#62BE12'
						my='sm'
						total={orders?.totalPages}
						onChange={handlePage}
					/>
				</Paper>
			</Flex>
		</Flex>
	)
}

export default MyOrders
