import {
	ActionIcon,
	Avatar,
	Badge,
	Box,
	Button,
	Card,
	em,
	Flex,
	Group,
	Image,
	Loader,
	Rating,
	Text,
} from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'
import { modals } from '@mantine/modals'
import { IconHeartFilled, IconPlus } from '@tabler/icons-react'
import { useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import fallbackImage from '../../../assets/Placholder-01.png'
import { Product } from '../../../interfaces/buyer/product/IProductByCategoryResponse.ts'
import { ISubCategoryProduct } from '../../../interfaces/buyer/product/IProductBySubCategory.ts'
import { IProduct } from '../../../interfaces/buyer/product/IProductResponse.ts'
import {
	addToCartAction,
	removeFromFavouriteAction,
} from '../../../store/product/productActions.ts'
import { useAppDispatch, useAppSelector } from '../../../store/store.ts'
import { discountCalculatorWithCurrency } from '../../../utils/discountCalculator.ts'
import {
	calculateRatings,
	calculateStars,
} from '../../../utils/ratingCalculator.ts'
import { round } from '../../../utils/roundOffFunction.ts'
import { getToken, getUser } from '../../../utils/storage.ts'

type IProductCardProps = {
	product: IProduct | Product | ISubCategoryProduct
	favourite?: boolean
	ageVerified?: string
}

const ProductCardNew = ({ product, favourite }: IProductCardProps) => {
	const isMobile = useMediaQuery(`(max-width: ${em(768)})`)
	const dispatch = useAppDispatch()
	const { loaders } = useAppSelector((state) => state.product)
	const navigate = useNavigate()
    const [prodId, setProdId] = useState('')
	const authUser = getUser()
	const isAgeVerificationRejected = authUser?.ageVerification === 'rejected'
	const isAgeVerificationPending = authUser?.ageVerification === 'pending'
	const productPrice = useMemo(
		() =>
			discountCalculatorWithCurrency(
				product?.productPrice.amount,
				product?.discount,
				product?.productPrice.currency
			),
		[product]
	)
	const roundedDiscount = useMemo(
		() => round(product?.productPrice.amount),
		[product]
	)
	const stars = useMemo(
		() =>
			calculateStars(product?.rating?.totalRating, product?.rating?.numRating),
		[product]
	)
	const ratings = useMemo(
		() =>
			calculateRatings(
				product?.rating?.totalRating,
				product?.rating?.numRating
			),
		[product]
	)

	return (
		<Flex
			my='sm'
			w={isMobile ? '165px' : '185px'}
			h={isMobile ? '260px' : '260px'}
			ml={10}
			style={{ cursor: 'pointer' }}
			onClick={() => {
				if (!getToken()) {
					navigate(`/product/${product?._id}`)
				} else {
					navigate(`/products/${product?._id}`)
				}
			}}
		>
			<Card w='100%' pos='relative' withBorder radius='md'>
				<Flex direction='column' gap={5} pos={'relative'} h='100%'>
					<Flex align='center' justify='center'>
						<Box
							bg={'white'}
							p={10}
							w={isMobile ? '160px' : '300px'}
							style={{
								borderRadius: '10px',
							}}
							h='140px'
						>
							<Image
								radius={'md'}
								loading='lazy'
								src={product?.productImage}
								fallbackSrc={fallbackImage}
								alt='image of mobile'
								fit='contain'
								w={'100%'}
								h={'100%'}
							/>
						</Box>
					</Flex>
					{loaders.addingItemToFavourite ? (
						<Loader size='sm' pos='absolute' top={-8} right={-6} />
					) : (
						<>
							{favourite && (
								<ActionIcon
									variant='subtle'
									pos='absolute'
									top={-8}
									right={-6}
									color='#E0E0E0'
									onClick={(e) => {
										e.stopPropagation()
										dispatch(
											removeFromFavouriteAction({ productId: product?._id })
										)
									}}
									radius='xl'
								>
									<IconHeartFilled size='20px' color='#C0FF00' />
								</ActionIcon>
							)}
						</>
					)}

					{loaders.addingItemToCart && product._id === prodId ? (
						<Loader size='sm' pos='absolute' top={110} right={-2} />
					) : (
						<ActionIcon
							size='sm'
							variant='filled'
							pos='absolute'
							top={110}
							right={-2}
							onClick={(e) => {
								e.stopPropagation()
                                setProdId(product._id)
								if (!getToken()) {
									navigate('/sign-in')
								} else {
									if (
										product?.category?.includes('Alcohol') ||
										product?.category?.includes('Smoke Essentials')
									) {
										if (isAgeVerificationRejected) {
											navigate('/buyer/age-verification')
											return
										} else if (isAgeVerificationPending) {
											modals.open({
												// title: 'Age Verification Pending',
												centered: true,
												children: (
													<Flex direction='column' gap='sm'>
														<Flex w='100%' justify='center'>
															<Avatar
																size={isMobile ? '150px' : '200px'}
																radius='xs'
																src='https://res.cloudinary.com/dhl8hhlsx/image/upload/v1726034777/Icons/epr04dscthif0gtasqib.png'
															/>
														</Flex>
														<Text ta='center' size={isMobile ? 'md' : 'lg'}>
															Your Account is under consideration for Age
															Verification
														</Text>
														<Group justify='center'>
															<Button
																fw={200}
																radius='xl'
																w='30%'
																onClick={() => modals.closeAll()}
															>
																Okay
															</Button>
														</Group>
													</Flex>
												),
											})
											return
										}
									}
									dispatch(addToCartAction({ productId: product?._id }))
								}
							}}
							color='#AAE104'
							radius='xl'
						>
							<IconPlus size='18px' color='#C0FF00' />
						</ActionIcon>
					)}

					<Flex direction='column'>
						<Flex gap='sm'>
							<Flex align={'center'} my={5} gap={10}>
								<Text size='md' c={'#AAE104'} fw={700}>
									{productPrice}
								</Text>
								{product?.discount > 0 && (
									<Text
										c={'#979797'}
										size='sm'
										fw={product?.discount > 0 ? 500 : 600}
										td={product?.discount > 0 ? 'line-through' : ''}
									>
										${roundedDiscount}
									</Text>
								)}
							</Flex>
						</Flex>
						<Text lineClamp={2} fw={'500'} size={isMobile ? '14px' : '14px'}>
							{product?.productName}
						</Text>
						<Flex
							align={'center'}
							pos={'absolute'}
							bottom={-10}
							mt={5}
							gap={20}
						>
							<Rating
								defaultValue={stars}
								color='#FFC633'
								size='sm'
								readOnly
								fractions={4}
							/>
							<Text size='sm' c={'#979797'}>
								{ratings}
							</Text>
						</Flex>
					</Flex>
					<Badge
						variant='light'
						pos={'absolute'}
						top={-16}
						left={-20}
						color='#FF464F'
						radius={'none'}
						style={{
							borderTopLeftRadius: '10px',
							borderBottomRightRadius: '10px',
						}}
					>
						<Text size='xs' fw={'bold'}>
							{product?.discount}% off
						</Text>
					</Badge>
				</Flex>
			</Card>
		</Flex>
	)
}

export default ProductCardNew
