import {useLocation, useNavigate} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../../store/store.ts";
import {useMediaQuery} from "@mantine/hooks";
import {Button, Card, em, Flex, Image, Loader, Text, Title} from "@mantine/core";
import {useEffect} from "react";
import {searchProductsAction} from "../../../store/product/productActions.ts";
import ProductCardNew from "./ProductCardNew.tsx";
import Colors from "../../../constants/colors.ts";

const ProductsBySearch = () => {
    const location = useLocation();
    // const queryParams = new URLSearchParams(location.search);
    // const category = queryParams.get('search');
    const stateProduct = location?.state?.search
    const dispatch = useAppDispatch()
    const {searchedProducts, loaders} = useAppSelector(state => state.product)
    const isSmall = useMediaQuery(`(max-width: ${em(768)})`)
    const navigate = useNavigate()

    useEffect(() => {
        console.log('search value', stateProduct)
        if (!stateProduct) return
        dispatch(searchProductsAction(stateProduct))

    }, [stateProduct]);
    return (
        <>
            {loaders.searchingProducts ?
                <Flex h="100vh" w="100%" align="center" justify="center">
                    <Loader/>
                </Flex> :
                searchedProducts?.products?.length === 0 ?
                    <Flex
                        w='100%'
                        h={'100%'}
                        mt={100}
                        gap={10}
                        justify='center'
                        align='center'
                        direction={'column'}
                        my='xl'
                        // bg={'green'}

                    >
                        <Image src={'https://crazybyrasel.com/assets/New%20Search%20Not%20Found-01-BzkZU3ni.png'}
                               h={300} w={300}/>
                        <Title ta={'center'} order={2}>There were no search results for {stateProduct}</Title>
                        <Text fz={'sm'} c={'gray'}>
                            Please check your spelling or use different keywords.
                        </Text>
                        <Flex direction={'column'}>
                            <Button
                                mt={20}
                                size='lg'
                                radius={'xl'}
                                color={Colors.primaryColor}
                                onClick={() => navigate('/')}
                            >
                                Back to home
                            </Button>
                        </Flex>
                    </Flex>
                    :
                    <Flex w="100%" direction="column">
                        <Card w="100%" pos="fixed" style={{zIndex: "100"}}>
                            <Flex w="100%" justify="center">
                                <Flex justify="space-between" align="center" w={isSmall ? "100%" : "70%"}>
                                    <Title order={4}>{searchedProducts?.length} Related Products
                                        found</Title>
                                    {/*<Button radius="xl" color="#62BE12">Create Coupon</Button>*/}
                                </Flex>
                            </Flex>
                        </Card>
                        <Flex justify="center" mt={60}>
                            <Flex direction="column" maw={isSmall ? "100%" : "70%"}>
                                <Flex wrap="wrap" justify={isSmall ? "center" : ""} mx="xs">
                                    {searchedProducts?.products?.map((product, index) => {
                                        return (

                                            <ProductCardNew key={index} product={product}/>

                                        )
                                    })}
                                </Flex>
                            </Flex>
                        </Flex>
                    </Flex>
            }

        </>
    );
};

export default ProductsBySearch;