import { createAsyncThunk } from '@reduxjs/toolkit'
import { AxiosError } from 'axios'
import axiosInstance from '../../config/axios.config.ts'
import { ISubCategoriesResponse } from '../../interfaces/buyer/categories/ISubCategoriesResponse.ts'
import { errorHandler } from '../../utils/errorHandler.ts'

export const getAllCategoriesAction = createAsyncThunk(
	'categories/getAllCategories',
	async () => {
		try {
			const res = await axiosInstance.get('category')
			return res.data
		} catch (e) {
			const error = e as AxiosError
			errorHandler(error)
			console.error(e)
		}
	}
)
export const getAllGuestCategoriesAction = createAsyncThunk(
	'categories/getAllGuestCategories',
	async () => {
		try {
			const res = await axiosInstance.get('guest/categories')

			return res.data
		} catch (e) {
			const error = e as AxiosError
			errorHandler(error)
			console.error(e)
		}
	}
)

export const getSubCategoriesAction = createAsyncThunk(
	'categories/getSubCategories',
	async (id: string) => {
		try {
			const res = await axiosInstance.get<ISubCategoriesResponse>(
				`subCategory/get-subCategories-specific-category?categoryId=${id}`
			)
			return res.data
		} catch (e) {
			const error = e as AxiosError
			errorHandler(error)
			console.error(e)
		}
	}
)
