import {useMediaQuery} from "@mantine/hooks";
import {em, Skeleton} from "@mantine/core";

const CategorySkeleton = () => {
    const isMobile = useMediaQuery(`(max-width: ${em(768)})`);
    return (
        <Skeleton
            height={isMobile ? 65 : 60}
            width={isMobile ? 65 : 70}/>
    );
};

export default CategorySkeleton;