import {
	Accordion,
	Box,
	Card,
	em,
	Flex,
	Image,
	NavLink,
	Text,
} from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'
import { IconPlus } from '@tabler/icons-react'
import { useEffect, useState } from 'react'
import faqImage from '../../assets/FAQs_512_x_512px-01.png'
import {
	buyerFaqs,
	driverFaqs,
	privacyFaqs,
	sellersFaqs,
	termsAndCondition,
} from '../../utils/faqData.ts'
import classes from './ContactUs.module.css'

export const data = [
	{ label: 'buyer', description: "Buyer's FAQs" },
	{ label: 'seller', description: "Seller's FAQs" },
	{ label: 'driver', description: "Driver's FAQs" },
	{ label: 'privacy', description: 'Privacy Policy' },
	{ label: 'terms', description: 'Terms and Conditions' },
]

const FAQs = () => {
	const isMobile = useMediaQuery(`(max-width: ${em(768)})`)
	const [faqItem, setFaqItem] = useState('buyer')

	const items = data.map((item, index) => (
		<NavLink
			key={index}
			active={item.label === faqItem}
			label={item.description}
			onClick={() => setFaqItem(item.label)}
			variant='filled'
		/>
	))

	const buyersFaqItems = buyerFaqs.map((item, index) => (
		<Accordion.Item key={index} value={item.value}>
			<Accordion.Control>{item.value}</Accordion.Control>
			<Accordion.Panel>{item.description}</Accordion.Panel>
		</Accordion.Item>
	))

	const sellersFaqItems = sellersFaqs.map((item, index) => (
		<Accordion.Item key={index} value={item.value}>
			<Accordion.Control>{item.value}</Accordion.Control>
			<Accordion.Panel>{item.description}</Accordion.Panel>
		</Accordion.Item>
	))

	const driverFaqItems = driverFaqs.map((item, index) => (
		<Accordion.Item key={index} value={item.value}>
			<Accordion.Control>{item.value}</Accordion.Control>
			<Accordion.Panel>{item.description}</Accordion.Panel>
		</Accordion.Item>
	))

	const privacyFaqItems = privacyFaqs.map((item, index) => (
		<Accordion.Item key={index} value={item.value}>
			<Accordion.Control>{item.value}</Accordion.Control>
			<Accordion.Panel>{item.description}</Accordion.Panel>
		</Accordion.Item>
	))

	const termsAndConditionsFaqItems = termsAndCondition.map((item, index) => (
		<Accordion.Item key={index} value={item.value}>
			<Accordion.Control>{item.value}</Accordion.Control>
			<Accordion.Panel>{item.description}</Accordion.Panel>
		</Accordion.Item>
	))

	useEffect(() => {
		window.scrollTo({ top: 0, behavior: 'smooth' })
	}, [])

	return (
		<Flex direction='column' w='100%' justify='center' align='center'>
			<Flex bg='#f2f1ed' justify='center' p='sm'>
				<Flex
					w={isMobile ? '100%' : '70%'}
					gap='xl'
					direction={isMobile ? 'column-reverse' : 'row'}
					align='center'
				>
					<Flex w={isMobile ? '100%' : '50%'} direction='column'>
						<Text size={isMobile ? '40px' : '60px'}>FAQs</Text>
						<Text mt={isMobile ? 'xs' : 'xl'}>
							Have a question? Here you'll find the answers most valued by our
							partners, along with access to step-by-step instructions and
							support.
						</Text>
					</Flex>
					<Flex w='50%' mt={isMobile ? 'sm' : 'xs'}>
						<Box h={500} w={500}>
							<Image src={faqImage} />
						</Box>
					</Flex>
				</Flex>
			</Flex>
			<Flex
				w={isMobile ? '100%' : '70%'}
				my='sm'
				direction={isMobile ? 'column' : 'row'}
				p='sm'
				gap='lg'
			>
				<Flex w={isMobile ? '100%' : '50%'} direction='column' justify='center'>
					<Card radius='md' bg='#F8F9FA'>
						{items}
					</Card>
				</Flex>
				<Flex w={isMobile ? '100%' : '50%'} direction='column'>
					<Text size='30px' mb='xs'>
						FAQs
					</Text>
					<Accordion
						mt='lg'
						radius='md'
						transitionDuration={500}
						chevronPosition='left'
						classNames={{ chevron: classes.chevron }}
						chevron={<IconPlus className={classes.icon} />}
						variant='separated'
						defaultValue='Apples'
					>
						{faqItem === 'buyer'
							? buyersFaqItems
							: faqItem === 'seller'
							? sellersFaqItems
							: faqItem === 'driver'
							? driverFaqItems
							: faqItem === 'privacy'
							? privacyFaqItems
							: termsAndConditionsFaqItems}
					</Accordion>
				</Flex>
			</Flex>
		</Flex>
	)
}

export default FAQs
