import { BackgroundImage, Box, Card, em, Flex, Image } from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'
import React from 'react'
import { Navigate, useNavigate } from 'react-router-dom'
import background from '../../assets/login-background.png'
import scooter from '../../assets/login-scooter.png'
import logo from '../../assets/logo.png'
import { getToken } from '../../utils/storage.ts'

type CenterLayoutProps = {
	children: React.ReactNode
}
const CenterLayout = ({ children }: CenterLayoutProps) => {
	const isMobile = useMediaQuery(`(max-width: ${em(768)})`)
	const isLaptop = useMediaQuery(`(max-width: ${em(992)})`)
	const navigate = useNavigate()

	if (getToken()) {
		return <Navigate to='/buyer' replace={true} />
	}

	return (
		<BackgroundImage h='100vh' autoFocus src={background}>
			<Image
				loading='eager'
				src={logo}
				pos={'absolute'}
				top={10}
				left={30}
				w={190}
				onClick={() => navigate('/')}
				style={{ cursor: 'pointer' }}
			/>

			<Flex w='100%' justify='space-evenly' align='center' h='100%' p='sm'>
				<Box h='60%' w='50%' hidden={isMobile ? true : false}>
					<Image
						visibleFrom='sm'
                        mx={'auto'}
						h='100%'
						w='auto'
						src={scooter}
						loading='eager'
					/>
				</Box>
				<Card
					w={isMobile ? '100%' : isLaptop ? '50%' : '35%'}
					mr={isMobile ? 0 : 80}
					bg={'#FFFFFFED'}
					radius={isMobile ? '20px' : '40px'}
				>
					{children}
				</Card>
			</Flex>
		</BackgroundImage>
	)
}

export default CenterLayout
