import { Box, Button, em, Flex, Group, Image, Slider, Text, Title } from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'
import loyaltyTracking from '../../../assets/loyalty tracking.png'
import medal from "../../../assets/medal.png"
import coin from "../../../assets/coin.png"
import loyaltyTiger from '../../../assets/loylty tiger.png'
import { IconCheck, IconShoppingCartFilled } from '@tabler/icons-react'
import playstoreImage from "../../../assets/playstore.png"
import appleImage from "../../../assets/apple-logo.png"
import tigerImage from "../../../assets/tiger it mobile.png"
import classes from "./Loyalty.module.css"
import { useNavigate } from 'react-router-dom'
import Colors from '../../../constants/colors'
import {getUser} from "../../../utils/storage.ts";

const LoyaltyTracking = () => {
    const user = getUser()
    const isMobile = useMediaQuery(`(max-width: ${em(768)})`)
    const navigate = useNavigate()

    const marks = [
        { value: 20, label: `25` },
        { value: 50, label: '50' },
        { value: 100, label: '100' },
        { value: 150, label: '150' },
        { value: 200, label: '200' },
        { value: 250, label: '250' },
    ]


    return (
        <>
            <Flex direction={'column'} w={'100%'}>
                <Flex
                    w={'100%'}
                    bg={'#AAE1044D'}
                    align={'center'}
                    justify={'space-between'}
                    direction={isMobile ? 'column' : 'row'}
                    p={isMobile ? 20 : 50}
                >
                    <Flex direction={'column'} gap={20}>
                        <Title fz={isMobile ? 35 : 65}>
                            Welcome,{' '}
                            {user?.firstName && (
                                <span
                                    style={{
                                        color: Colors.primaryColor,
                                    }}
                                >
									{user?.firstName}!
								</span>
                            )}
                        </Title>
                        <Title fz={isMobile ? 35 : 65}>
                            Your Loyalty,{' '}
                            <span
                                style={{
                                    color: Colors.primaryColor,
                                }}
                            >
								Rewarded
							</span>
                        </Title>
                        <Text>Your Loyalty Progress at a Glance</Text>
                        {/* <Button
                            color='black'
                            c={Colors.primaryColor}
                            w={'max-content'}
                            radius={'xl'}
                            size={isMobile ? 'md' : 'xl'}
                            style={{
                                boxShadow: '-4px 29px 48px -2px rgba(170,225,4,0.36)',
                            }}
                        >
                            Redeem Now
                        </Button> */}
                    </Flex>
                    <Flex direction={'column'} gap={20}>
                        <Box h={isMobile ? 300 : 600}>
                            <Image h={'100%'} src={loyaltyTracking} />
                        </Box>
                    </Flex>
                </Flex>
                <Flex my={isMobile ? 30 : 100} w={'100%'} direction={'column'}>
                    <Flex
                        w={'100%'}
                        gap={isMobile ? 40 : 20}
                        direction={'column'}
                        align={'center'}
                        justify={'center'}
                    >
                        <Title fz={isMobile ? 35 : 75}>
                            My{' '}
                            <span
                                style={{
                                    color: Colors.primaryColor,
                                }}
                            >
								Points
							</span>{' '}
                        </Title>
                        <Flex
                            w={isMobile ? '90%' : '95%'}
                            style={{
                                borderRadius: '10px',
                            }}
                            bg={'#1E1E1E'}
                            p={isMobile ? 30 : 80}
                            mx={isMobile ? 20 : 0}
                            gap={isMobile ? 29 : 0}
                            justify={'space-between'}
                            direction={isMobile ? 'column' : 'row'}
                        >
                            <Flex
                                direction={'column'}
                                align={isMobile ? 'center' : ''}
                                gap={25}
                            >
                                <Group>
                                    <Text
                                        c={'white'}
                                        fz={isMobile ? 20 : 26}
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <Image
                                            src={medal}
                                            h={isMobile ? 30 : 50}
                                            w={isMobile ? 30 : 50}
                                        />
                                        Bronze Member{' '}
                                    </Text>
                                </Group>
                                <Text c={'white'} fz={isMobile ? 20 : 26}>
                                    Current Balance
                                </Text>
                                <Title
                                    fz={60}
                                    c={Colors.primaryColor}
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: '5px',
                                    }}
                                >
                                    {user?.loyaltyPoints} <Image src={coin} h={40} w={40} />
                                </Title>
                                <Text
                                    c={'white'}
                                    ta={isMobile ? 'center' : undefined}
                                    fz={isMobile ? 16 : 23}
                                    w={isMobile ? '100%' : 450}
                                >
                                    You're only{' '}
                                    <span
                                        style={{
                                            color: Colors.primaryColor,
                                        }}
                                    >
										300 coins
									</span>{' '}
                                    away from Gold Tier! Don’t miss out on double rewards this
                                    weekend.
                                </Text>
                                <Button
                                    c={'black'}
                                    size={isMobile ? 'md' : 'xl'}
                                    w={'max-content'}
                                    radius={'xl'}
                                    onClick={() => navigate(-1)}
                                >
                                    Use Now
                                </Button>
                            </Flex>
                            <Box>
                                <Image
                                    h={isMobile ? 250 : 450}
                                    mx={isMobile ? 'auto' : ''}
                                    w={isMobile ? 'auto' : ''}
                                    src={loyaltyTiger}
                                />
                            </Box>
                        </Flex>
                    </Flex>
                </Flex>
                <Flex
                    my={isMobile ? 30 : 100}
                    w={'100%'}
                    gap={100}
                    direction={'column'}
                    p={isMobile ? 0 : 20}
                >
                    <Flex w={'100%'} justify={'center'}>
                        <Title fz={isMobile ? 35 : 75}>
							<span
                                style={{
                                    color: Colors.primaryColor,
                                }}
                            >
								Loyalty
							</span>{' '}
                            Track
                        </Title>
                    </Flex>
                    <Flex
                        w={'100%'}
                        align={'center'}
                        justify={'space-between'}
                        direction={isMobile ? 'column' : 'row'}
                        px={isMobile ? 15 : 30}
                    >
                        <Flex
                            direction={'column'}
                            gap={isMobile ? 30 : 20}
                            w={isMobile ? '100%' : '40%'}
                        >
                            <Text>90% to your next reward</Text>

                            <Slider
                                styles={{
                                    mark: {
                                        height: isMobile ? '10px' : '20px',
                                        width: isMobile ? '10px' : '20px',
                                        alignItems: 'center',
                                        fontSize: '29px',
                                    },

                                    markLabel: {
                                        fontSize: '18px',
                                    },
                                }}
                                size={isMobile ? 'sm' : 'xl'}
                                value={40}
                                max={300}
                                thumbSize={isMobile ? 30 : 35}
                                thumbChildren={
                                    <Box
                                        style={{
                                            height: '20px',
                                            width: '20px',
                                            backgroundColor: 'black',
                                            borderRadius: '100%',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <IconCheck size={14} />
                                    </Box>
                                }
                                marks={marks}
                            />
                            <Title fw={'normal'} fz={isMobile ? 30 : 55} my={40}>
                                Download the{' '}
                                <span
                                    style={{
                                        color: Colors.primaryColor,
                                    }}
                                >
									Tiger it app
								</span>{' '}
                                and discover our new world
                            </Title>
                            {!isMobile && (
                                <Group gap={isMobile ? 10 : 30} w={'100%'}>
                                    <Button
                                        component='a'
                                        href='https://play.google.com/store/apps/details?id=com.crazytechlabs.tigerit&pli=1'
                                        target='_blank'
                                        color='#AAE104'
                                        size={isMobile ? 'lg' : 'xl'}
                                        radius={'xl'}
                                        w={'45%'}
                                        p={0}
                                    >
                                        <Flex justify={'space-between'} w={'100%'} align={'center'}>
                                            <Image
                                                src={playstoreImage}
                                                h={isMobile ? 20 : 30}
                                                mr={15}
                                            />{' '}
                                            <Flex direction={'column'}>
                                                <Text c={'black'} fz={'xs'}>
                                                    Get it on
                                                </Text>
                                                <Text
                                                    c={'black'}
                                                    fz={isMobile ? 'sm' : 'md'}
                                                    fw={'bold'}
                                                >
                                                    Google Play
                                                </Text>
                                            </Flex>
                                        </Flex>
                                    </Button>
                                    <Button
                                        component='a'
                                        href='https://apps.apple.com/pk/app/tiger-it/id6474063205'
                                        target='_blank'
                                        color='#AAE104'
                                        size={isMobile ? 'lg' : 'xl'}
                                        radius={'xl'}
                                        w={'45%'}
                                        p={0}
                                    >
                                        {' '}
                                        <Image
                                            src={appleImage}
                                            h={isMobile ? 20 : 30}
                                            mr={15}
                                        />{' '}
                                        <Flex direction={'column'}>
                                            <Text c={'black'} fz={'xs'}>
                                                Get it on
                                            </Text>
                                            <Text c={'black'} fz={isMobile ? 'sm' : 'md'} fw={'bold'}>
                                                App Store
                                            </Text>
                                        </Flex>
                                    </Button>
                                </Group>
                            )}
                        </Flex>
                        <Flex
                            w={isMobile ? '100%' : ''}
                            direction={'column'}
                            align={'center'}
                            gap={100}
                        >
                            <Box pos={'relative'} className={classes.circle}>
                                <Image
                                    h={isMobile ? 400 : 700}
                                    top={-30}
                                    style={{
                                        objectFit: 'contain',
                                    }}
                                    pos={'absolute'}
                                    src={tigerImage}
                                />
                            </Box>
                            {isMobile && (
                                <Group gap={isMobile ? 10 : 30} w={'100%'}>
                                    <Button
                                        component='a'
                                        href='https://play.google.com/store/apps/details?id=com.crazytechlabs.tigerit&pli=1'
                                        target='_blank'
                                        color='#AAE104'
                                        size={isMobile ? 'lg' : 'xl'}
                                        radius={'xl'}
                                        w={'45%'}
                                        p={0}
                                    >
                                        <Flex justify={'space-between'} w={'100%'} align={'center'}>
                                            <Image
                                                src={playstoreImage}
                                                h={isMobile ? 20 : 30}
                                                mr={15}
                                            />{' '}
                                            <Flex direction={'column'}>
                                                <Text c={'black'} fz={'xs'}>
                                                    Get it on
                                                </Text>
                                                <Text
                                                    c={'black'}
                                                    fz={isMobile ? 'sm' : 'md'}
                                                    fw={'bold'}
                                                >
                                                    Google Play
                                                </Text>
                                            </Flex>
                                        </Flex>
                                    </Button>
                                    <Button
                                        component='a'
                                        href='https://apps.apple.com/pk/app/tiger-it/id6474063205'
                                        target='_blank'
                                        color='#AAE104'
                                        size={isMobile ? 'lg' : 'xl'}
                                        radius={'xl'}
                                        w={'45%'}
                                        p={0}
                                    >
                                        {' '}
                                        <Image
                                            src={appleImage}
                                            h={isMobile ? 20 : 30}
                                            mr={15}
                                        />{' '}
                                        <Flex direction={'column'}>
                                            <Text c={'black'} fz={'xs'}>
                                                Get it on
                                            </Text>
                                            <Text c={'black'} fz={isMobile ? 'sm' : 'md'} fw={'bold'}>
                                                App Store
                                            </Text>
                                        </Flex>
                                    </Button>
                                </Group>
                            )}
                        </Flex>
                    </Flex>
                </Flex>
                <Flex w={'100%'} direction={'column'} my={30} gap={20}>
                    <Flex w={'100%'} justify={'center'}>
                        <Title fz={isMobile ? 35 : 65}>
							<span
                                style={{
                                    color: Colors.primaryColor,
                                }}
                            >
								Earn More
							</span>{' '}
                            Coins
                        </Title>
                    </Flex>
                    <Flex bg={'#E6F6B4'} w={'100%'} py={50}>
                        <Flex
                            w={'100%'}
                            direction={'column'}
                            align={'center'}
                            justify={'center'}
                            gap={20}
                        >
                            <Box
                                bg={'black'}
                                style={{
                                    height: '60px',
                                    width: '60px',
                                    borderRadius: '50%',
                                    backgroundColor: 'black',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                            >
                                <IconShoppingCartFilled
                                    size={35}
                                    style={{
                                        color: Colors.primaryColor,
                                    }}
                                />
                            </Box>
                            <Title fw={'normal'} fz={isMobile ? 25 : 45}>
                                Shop more to earn loyalty coins
                            </Title>
                            <Button
                                size='md'
                                radius={'xl'}
                                c={'black'}
                                onClick={() => {
                                    navigate('/')
                                }}
                            >
                                Shop Now
                            </Button>
                        </Flex>
                    </Flex>
                </Flex>
            </Flex>
        </>
    )
}

export default LoyaltyTracking
