import {
	Box,
	Button,
	Container,
	Group,
	Image,
	Text,
	Title,
} from '@mantine/core'
import { useNavigate } from 'react-router-dom'
import notFound from '../assets/404 Error Tiger itt.png'
import classes from './NothingFoundBackground.module.css'
import Colors from '../constants/colors'

const ErrorScreen = () => {
	const navigate = useNavigate()
	return (
		<>
			<Container className={classes.root}>
				<div className={classes.inner}>
					<div className={classes.content}>
						<Box h={550}>
							<Image src={notFound} h={'100%'} style={{
								objectFit: 'contain'
							}} />
						</Box>
						<Title className={classes.title}>Nothing to see here</Title>
						<Text
							c='dimmed'
							size='lg'
							ta='center'
							className={classes.description}
						>
							Page you are trying to open does not exist. You may have mistyped
							the address, or the page has been moved to another URL. If you
							think this is an error contact support.
						</Text>
						<Group justify='center'>
							<Button
								onClick={() => {
									navigate('/')
								}}
								color={Colors.primaryColor}
								size='lg'
								radius={'xl'}
							>
								Go back
							</Button>
						</Group>
					</div>
				</div>
			</Container>
		</>
	)
}

export default ErrorScreen
