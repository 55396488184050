import { Box, Skeleton } from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'

const SliderSkeleton = () => {
	const isMobile = useMediaQuery(`(max-width: 468px)`)
	return (
		<Box h={isMobile ? 200 : 301} w={isMobile ? 370 : 505} p='sm'>
			<Skeleton radius='lg' height={isMobile ? 150 : '80%'} width='100%' />
		</Box>
	)
}

export default SliderSkeleton
