import {
	Anchor,
	Box,
	Button,
	Card,
	em,
	Flex,
	Group,
	Loader,
	Radio,
	Select,
	Stepper,
	Textarea,
	TextInput,
} from '@mantine/core'
import { useEffect, useState } from 'react'
import classes from './Cart.module.css'
import {
	IconNotebook,
	IconTruckDelivery,
	IconWallet,
} from '@tabler/icons-react'
import { useDisclosure, useMediaQuery } from '@mantine/hooks'
import { useAppDispatch, useAppSelector } from '../../../store/store.ts'
import {
	createBuyNowOrderAction,
	createOrderAction,
	getAllCartItems,
} from '../../../store/product/productActions.ts'
import { useLocation, useNavigate } from 'react-router-dom'
import {
	IBuyNowCreateOrder,
	ICreateOrder,
} from '../../../interfaces/buyer/cart/ICreateOrder.ts'
import { useForm } from '@mantine/form'
import { IAddress } from '../../../interfaces/account/IAccount.ts'
import { getAddress, getBuyNowItems, getUser } from '../../../utils/storage.ts'
import OrderInProgress from './OrderInProgress.tsx'
import GoogleMapDrawer from '../../../components/drawers/GoogleMapDrawer.tsx'
import GoogleMapModal from '../../../components/modals/GoogleMapModal.tsx'
import { toggleCartAction } from '../../../store/product/productSlice.ts'

// interface IStepOne {
//     name: string
//     email: string
//     phone: number | string
// }

const Cart = () => {
	const [active, setActive] = useState(0)
	const isMobile = useMediaQuery(`(max-width: ${em(768)})`)
	const location = useLocation()
	const queryParams = new URLSearchParams(location.search)
	const state = queryParams.get('state')
	// const [modelOpened, {open: openModal, close: closeModal}] = useDisclosure(false);
	const [paymentMethod, setPaymentMethod] = useState('wallet')
	const { cart, loaders, products } = useAppSelector((state) => state.product)
	const { address } = useAppSelector((state1) => state1.account)
	const [orderFailMessage, setOrderFailMessage] = useState({
		message: '',
		success: true,
	})
	const [mapOpened, { open, close }] = useDisclosure(false)
	// const {userProfile} = useAppSelector(state => state.account)
	const user = getUser()
	const userAddress = getAddress()
	// const [user, setUser] = useState<AuthUser>({
	//     email: "",
	//     firstName: "",
	//     lastName: "",
	//     loyaltyPoints: 0,
	//     profilePic: "",
	//     role: "",
	//     ageVerification: "",
	//     phoneNumber: "",
	//     dob: "",
	//     gender: ""
	// })
	const dispatch = useAppDispatch()
	const navigate = useNavigate()

	const [stepTwo, setStepTwo] = useState<IAddress>(userAddress)
	const [pickUpNote, setPickUpNote] = useState(false)
	const [note, setNote] = useState('')
	// const [totalPrice, setTotalPrice] = useState(0)

	const form = useForm({
		initialValues: {
			name: user.firstName,
			phone: '',
			email: user.email,
			deliveryType: products?.ipInfo?.country === 'PK' ? 'Delivery' : '',
		},

		validate: (values) => {
			if (active === 0) {
				return {
					name: values.name.trim()?.length <= 0 ? 'should not be empty' : null,
					email: /^\S+@\S+$/.test(values.email) ? null : 'Invalid email',
					phone: values.phone?.length <= 0 ? 'Please enter phone number' : null,
				}
			}
			if (active === 1) {
				return {
					deliveryType:
						values.deliveryType.trim()?.length <= 0
							? 'Please select delivery type'
							: null,
				}
			}

			return {}
		},
	})

	const handlePlaceOrder = () => {
		const commonData = {
			shippingAddress: stepTwo,
			name: form.values.name,
			email: form.values.email,
			note: note,
			paymentMethod: paymentMethod,
			cartDelete: true,
			deliveryType: form.values.deliveryType.toLowerCase(),
			phone: form.values.phone,
		}

		if (state?.includes('buyNow')) {
			const items = getBuyNowItems()
			const data: IBuyNowCreateOrder = { ...commonData, items }
			dispatch(createBuyNowOrderAction(data)).then((value) => {
				if (!value.payload.success) {
					setOrderFailMessage({
						message: value.payload.message,
						success: value.payload.success,
					})
					dispatch(toggleCartAction(0))
				}
			})
		} else {
			const data: ICreateOrder = { ...commonData, couponInfo: [] }
			dispatch(createOrderAction(data)).then((value) => {
				if (!value.payload.success) {
					setOrderFailMessage({
						message: value.payload.message,
						success: value.payload.success,
					})
				}
			})
		}
	}
	const nextStep = () => {
		setActive((current) => {
			if (form.validate().hasErrors) {
				return current
			}
			return current < 3 ? current + 1 : current
		})

		if (active === 2) {
			handlePlaceOrder()
		}
		if (active === 3) {
			navigate('/buyer')
		}
	}
	const prevStep = () => {
		setActive((current) => (current > 0 ? current - 1 : current))

		if (active === 0) {
			navigate(-1)
		}
		if (active === 3) {
			navigate('/buyer')
		}
	}

	useEffect(() => {
		dispatch(getAllCartItems())
		setStepTwo(userAddress)
		if (!user) return
		form.setValues({ ...form, email: user.email })
		form.setValues({ ...form, name: user.firstName })
		form.setValues({ ...form, phone: user.phoneNumber })
	}, [])

	// useEffect(() => {
	//     let sum = 0
	//     cart?.cart?.details.forEach((value) => {
	//         // sum += discountCalculator(value.product.productPrice.amount, value.product.discount)
	//         console.log(value)
	//     })
	//     setTotalPrice(parseFloat(sum.toFixed(2)))
	// }, [cart]);

	// useEffect(() => {
	//     if (!userProfile?.user.personalInformation.personalAddress) return
	//
	//     // if (address) {
	//     //     setStepTwo(address)
	//     // } else {
	//     //     setStepTwo(userProfile?.user.personalInformation.personalAddress)
	//     // }
	//     form.setValues({...form, email: user.email})
	//     form.setValues({...form, name: user.firstName})
	// }, []);

	return (
		<Flex direction={isMobile ? 'column' : 'row'} justify='center'>
			<Box w={isMobile ? '100%' : '70%'}>
				{/*<Text>Checkout</Text>*/}
				<Stepper
					size={isMobile ? 'xs' : 'lg'}
					color='#62BE12'
					p='sm'
					active={active}
					onStepClick={setActive}
					allowNextStepsSelect={false}
					classNames={{ step: classes.step }}
				>
					<Stepper.Step label={isMobile ? 'Contact' : 'Contact Details'}>
						<TextInput
							{...form.getInputProps('name')}
							my='lg'
							label='Full Name'
							w='100%'
							size={isMobile ? 'md' : 'lg'}
							radius='md'
							placeholder={user.firstName}
						/>
						<TextInput
							{...form.getInputProps('email')}
							my='lg'
							label='Email'
							w='100%'
							size={isMobile ? 'md' : 'lg'}
							radius='md'
							placeholder={user.email}
						/>
						<TextInput
							{...form.getInputProps('phone')}
							max={10}
							prefix='+'
							my='lg'
							label='Phone Number'
							w='100%'
							size={isMobile ? 'md' : 'lg'}
							radius='md'
							placeholder='Phone Number'
						/>
					</Stepper.Step>
					<Stepper.Step label={isMobile ? 'Delivery' : 'Delivery Information'}>
						<Flex direction='column' gap='lg'>
							<Select
								{...form.getInputProps('deliveryType')}
								data={
									products?.ipInfo?.country === 'PK'
										? ['Delivery']
										: ['Pickup', 'Delivery']
								}
								placeholder='Select'
								label='Delivery Type'
								size={isMobile ? 'md' : 'lg'}
								radius='md'
							/>
							{form.values.deliveryType === 'Delivery' && (
								<>
									<TextInput
										disabled
										value={stepTwo?.state}
										placeholder='Enter state'
										label='State'
										size={isMobile ? 'md' : 'lg'}
										radius='md'
									/>
									<TextInput
										disabled
										value={stepTwo?.city}
										placeholder='City'
										label='City'
										size={isMobile ? 'md' : 'lg'}
										radius='md'
									/>
									<TextInput
										onClick={open}
										size={isMobile ? 'md' : 'lg'}
										radius='md'
										value={address}
										label='Address'
										placeholder='366 W 30th St, New York, NY 10001, USA'
									/>
								</>
							)}
							{pickUpNote && (
								<Textarea
									size={isMobile ? 'md' : 'lg'}
									radius={isMobile ? 'md' : 'lg'}
									onChange={(v) => {
										setNote(v.target.value)
									}}
									label='Add pickup note'
									placeholder='pickup note'
								/>
							)}
							<Group>
								<IconNotebook color='#62BE12' />
								<Anchor
									style={{ color: '#62BE12' }}
									my='lg'
									onClick={() => setPickUpNote(!pickUpNote)}
								>
									Add pickup note
								</Anchor>
							</Group>
						</Flex>
					</Stepper.Step>
					<Stepper.Step label={isMobile ? 'Payment' : 'Payment Method'}>
						<Radio.Group value={paymentMethod}>
							<Card
								withBorder
								w='100%'
								my='lg'
								style={{ cursor: 'pointer' }}
								onClick={() => {
									setPaymentMethod('wallet')
								}}
							>
								<Flex align='center'>
									<IconWallet color='#62BE12' />
									<Radio
										classNames={{ body: classes.body, label: classes.label }}
										w='100%'
										labelPosition='left'
										value='wallet'
										color='#62BE12'
										label='My Wallet'
									/>
								</Flex>
							</Card>
							<Card
								withBorder
								w='100%'
								my='lg'
								style={{ cursor: 'pointer' }}
								onClick={() => {
									setPaymentMethod('cashOnDelivery')
								}}
							>
								<Flex align='center'>
									<IconTruckDelivery color='#62BE12' />
									<Radio
										classNames={{ body: classes.body, label: classes.label }}
										w='100%'
										labelPosition='left'
										value='cashOnDelivery'
										color='#62BE12'
										label='Cash on delivery'
									/>
								</Flex>
							</Card>
						</Radio.Group>
					</Stepper.Step>
					<Stepper.Completed>
						{loaders.buyNow || loaders.creatingOrder ? (
							<Flex h='50vh' w='100%' justify='center' align='center'>
								<Loader />
							</Flex>
						) : (
							<OrderInProgress
								success={orderFailMessage.success}
								message={orderFailMessage.message}
							/>
						)}
					</Stepper.Completed>
				</Stepper>
				{!isMobile && (
					<Group justify='end' mt='xl' mx='sm'>
						<Button
							component='a'
							fw={200}
							radius='xl'
							size={isMobile ? 'sm' : 'md'}
							color='#62BE12'
							variant='outline'
							onClick={prevStep}
						>
							Back
						</Button>
						<Button
							component='a'
							fw={200}
							radius='xl'
							size={isMobile ? 'sm' : 'md'}
							disabled={cart?.cart?.details[0]?.products?.length <= 0}
							color='#62BE12'
							onClick={nextStep}
						>
							Next step
						</Button>
					</Group>
				)}
				{isMobile && (
					<Group
						justify='space-between'
						my='sm'
						px='sm'
						w='100%'
						pos='fixed'
						bottom={0}
					>
						<Button
							component='a'
							fw={200}
							w='45%'
							radius='xl'
							size={isMobile ? 'xxl' : 'md'}
							color='#62BE12'
							variant='outline'
							onClick={prevStep}
						>
							Back
						</Button>
						<Button
							component='a'
							fw={200}
							w='45%'
							radius='xl'
							size={isMobile ? 'xxl' : 'md'}
							disabled={cart?.cart?.details?.length <= 0}
							color='#62BE12'
							onClick={nextStep}
						>
							Next step
						</Button>
					</Group>
				)}
			</Box>
			{/*receipt modal*/}
			{/*<Modal size="lg" opened={modelOpened} onClose={closeModal} centered>*/}
			{/*    <PaymentReceipt totalPrice={totalPrice}/>*/}
			{/*</Modal>*/}
			{isMobile ? (
				<GoogleMapDrawer opened={mapOpened} onClose={close} />
			) : (
				<GoogleMapModal opened={mapOpened} onClose={close} />
			)}
		</Flex>
	)
}

export default Cart
