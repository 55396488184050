import { createAsyncThunk } from '@reduxjs/toolkit'
import { AxiosError } from 'axios'
import axiosInstance from '../../config/axios.config.ts'
import {
	IForgotPassword,
	IGoogleSingIn,
	IResetPassword,
	ISetCurrency,
	IUserCreate,
	IUserSignIn,
	IUserSignUp,
	IUserSignUpResponse,
	IUserUpdateResponse,
	IVerifyOtp,
	IVerifyOtpResponse,
} from '../../interfaces/account/IAccount.ts'
import { IAuthUserResponse } from '../../interfaces/account/IAuthUserResponse.ts'
import { PersonalInformation } from '../../interfaces/account/IAuthUserUpdateResponse.ts'
import { ICurrencies } from '../../interfaces/account/ICurrencies.ts'
import { ISetCurrencyResponse } from '../../interfaces/account/ISetCurrencyResponse.ts'
import { errorHandler } from '../../utils/errorHandler.ts'
import { notificationSystem } from '../../utils/notificationSystem.ts'
import {
	setAuthUser,
	setShortToken,
	setToken,
	setUserId,
} from '../../utils/storage.ts'

export const signupAction = createAsyncThunk(
	'account/signup',
	async (data: IUserSignUp) => {
		try {
			const res = await axiosInstance.post<IAuthUserResponse>(
				'auth/signup-email-otp',
				data
			)
			if (res.data.success) {
				notificationSystem('OTP sent', '', false)
			}
			return res.data
		} catch (e: unknown) {
			const error = e as AxiosError
			errorHandler(error)
			console.error(e)
		}
	}
)
export const verifyOtpAction = createAsyncThunk(
	'account/verifyOtp',
	async (data: IVerifyOtp) => {
		try {
			const res = await axiosInstance.post<IVerifyOtpResponse>(
				'auth/verify-account',
				data
			)
			if (res.data.success) {
				setShortToken(res.data.token)
				notificationSystem('OTP sent', res.data.message, false)
			}
			return res.data
		} catch (e: unknown) {
			const error = e as AxiosError
			errorHandler(error)
			console.error(e)
		}
	}
)
export const createAccount = createAsyncThunk(
	'account/createAccount',
	async (data: IUserCreate) => {
		try {
			const res = await axiosInstance.post<IUserSignUpResponse>(
				'auth/create-account',
				data
			)
			if (res.data.success) {
				notificationSystem('Account created', '', false)
			}
			return res.data
		} catch (e: unknown) {
			const error = e as AxiosError
			errorHandler(error)
			console.error(e)
		}
	}
)
export const signInAction = createAsyncThunk(
	'account/signIn',
	async (data: IUserSignIn) => {
		try {
			const res = await axiosInstance.post<IAuthUserResponse>(
				'auth/login-email',
				data
			)
			if (res.data.success) {
				setToken(res.data.token)
				setAuthUser({
					firstName: res.data.user.personalInformation.firstName,
					lastName: res.data.user.personalInformation.lastName,
					profilePic: res.data.user.personalInformation.profilePic,
					email: res.data.user.credentialDetails.email,
					role: res.data.user.role,
					loyaltyPoints: res.data.user.loyaltyPoints,
					ageVerification: res.data.user.personalInformation.idCard.status,
					phoneNumber: res.data.user.personalInformation.phoneNumber,
					dob: res.data.user.personalInformation.dob,
					gender: res.data.user.personalInformation.gender,
					currency: res.data.user.preferences.currency.name,
				})
				// setSellerStatus(res.data.user.sellerAccount.verificationStatus)
				setUserId(res.data.user._id)
			} else {
				notificationSystem('Something went wrong', res.data.message || '', true)
			}
			return res.data
		} catch (e: unknown) {
			const error = e as AxiosError
			errorHandler(error)
			console.error(e)
		}
	}
)
export const signInWithGoogleAction = createAsyncThunk(
	'account/signInWithGoogle',
	async (data: {
		IdToken: string | undefined
		deviceInfo: {
			fcm: string
		}
	}) => {
		try {
			const res = await axiosInstance.post<IAuthUserResponse>(
				'auth/google-login',
				data
			)
			if (res.data.success) {
				setToken(res.data.token)
				setAuthUser({
					firstName: res.data.user.personalInformation.firstName,
					lastName: res.data.user.personalInformation.lastName,
					profilePic: res.data.user.personalInformation.profilePic,
					email: res.data.user.credentialDetails.email,
					role: res.data.user.role,
					loyaltyPoints: res.data.user.loyaltyPoints,
					ageVerification: res.data.user.personalInformation.idCard.status,
					phoneNumber: res.data.user.personalInformation.phoneNumber,
					dob: res.data.user.personalInformation.dob,
					gender: res.data.user.personalInformation.gender,
					currency: res.data.user.preferences.currency.name,
				})
			} else {
				notificationSystem('Something went wrong', 'error while log in', true)
			}
			return res.data
		} catch (e) {
			const error = e as AxiosError
			errorHandler(error)
			console.error(e)
		}
	}
)
export const signInWithAppleAction = createAsyncThunk(
	'account/signInWithApple',
	async (data: { idToken: string | undefined; user: string | undefined }) => {
		try {
			const res = await axiosInstance.post<IAuthUserResponse>(
				'auth/apple-login',
				data
			)
			if (res.data.success) {
				setToken(res.data.token)
				setAuthUser({
					firstName: res.data.user.personalInformation.firstName,
					lastName: res.data.user.personalInformation.lastName,
					profilePic: res.data.user.personalInformation.profilePic,
					email: res.data.user.credentialDetails.email,
					role: res.data.user.role,
					loyaltyPoints: res.data.user.loyaltyPoints,
					ageVerification: res.data.user.personalInformation.idCard.status,
					phoneNumber: res.data.user.personalInformation.phoneNumber,
					dob: res.data.user.personalInformation.dob,
					gender: res.data.user.personalInformation.gender,
					currency: res.data.user.preferences.currency.name,
				})
			} else {
				notificationSystem('Something went wrong', '', true)
			}
			return res.data
		} catch (e: unknown) {
			notificationSystem('Something went wrong', '', true)
		}
	}
)
export const resetPasswordAction = createAsyncThunk(
	'account/resetPassword',
	async (data: IResetPassword) => {
		try {
			const res = await axiosInstance.post<IUserSignUpResponse>(
				'auth/reset-pass',
				data
			)
			if (res.data.success) {
				notificationSystem('Password Reset', '', false)
			} else {
				notificationSystem('Something went wrong', '', true)
			}
			return res.data
		} catch (e: unknown) {
			notificationSystem('Something went wrong', '', true)
		}
	}
)
export const forgotPasswordAction = createAsyncThunk(
	'account/forgotPassword',
	async (data: IForgotPassword) => {
		try {
			const res = await axiosInstance.post<IUserSignUpResponse>(
				'auth/forget-password',
				data
			)
			if (res.data.success) {
				notificationSystem('Account created', '', false)
			} else {
				notificationSystem('Something went wrong', res.data.message, true)
			}
			return res.data
		} catch (e: unknown) {
			notificationSystem('Something went wrong', '', true)
		}
	}
)

export const signInWithGoogle = createAsyncThunk(
	'account/forgotPassword',
	async (data: IGoogleSingIn) => {
		try {
			const res = await axiosInstance.post('auth/google-login', data)
			return res.data
		} catch (e) {
			console.log(e)
		}
	}
)
export const profileUpdateAction = createAsyncThunk(
	'account/updateProfile',
	async (data: PersonalInformation) => {
		try {
			const res = await axiosInstance.put<IAuthUserResponse>('auth', {
				personalInformation: data,
			})

			if (res.data.success) {
				notificationSystem('Profile updated', '', false)
				setAuthUser({
					firstName: res.data.user.personalInformation.firstName,
					lastName: res.data.user.personalInformation.lastName,
					profilePic: res.data.user.personalInformation.profilePic,
					email: res.data.user.credentialDetails.email,
					role: res.data.user.role,
					loyaltyPoints: res.data.user.loyaltyPoints,
					ageVerification: res.data.user.personalInformation.idCard.status,
					phoneNumber: res.data.user.personalInformation.phoneNumber,
					dob: res.data.user.personalInformation.dob,
					gender: res.data.user.personalInformation.gender,
					currency: res.data.user.preferences.currency.name,
				})
			} else {
				notificationSystem('Something went wrong', '', true)
			}
			return res.data
		} catch (e: unknown) {
			const error = e as AxiosError
			errorHandler(error)
			console.error(e)
		}
	}
)

export const getProfileDataAction = createAsyncThunk(
	'account/getProfileData',
	async () => {
		try {
			const res = await axiosInstance.get<IUserUpdateResponse>('auth/profile')
			return res.data
		} catch (e) {
			const err = e as AxiosError
			if (err.response?.status === 401) {
				setToken('')
			}
			if (err.response?.status === 404) {
				setToken('')
			}
		}
	}
)

export const ageVerificationAction = createAsyncThunk(
	'account/ageVerification',
	async (data: { front: string; back: string }) => {
		try {
			const res = await axiosInstance.patch('user/age-verification', data)
			if (res.data.success) {
				notificationSystem(
					'Age Verification',
					'Your account is under consideration for age verification',
					false
				)
			}
			return res.data
		} catch (e) {
			console.log(e)
		}
	}
)

export const updateProfilePictureAction = createAsyncThunk(
	'account/updateProfilePicture',
	async (data: { profilePic: File }) => {
		try {
			const res = await axiosInstance.put('auth/update-profile-pic', data)
			if (res.data.success) {
				notificationSystem('Picture updated successfully', '', false)
			}
			return res.data
		} catch (e) {
			const error = e as AxiosError
			errorHandler(error)
			console.error(e)
		}
	}
)

export const getCurrenciesAction = createAsyncThunk(
	'account/getCurrencies',
	async () => {
		try {
			const res = await axiosInstance.get<ICurrencies>('common/get-currencies')
			return res.data
		} catch (e) {
			notificationSystem('Something went wrong', '', true)
		}
	}
)
export const setCurrenciesAction = createAsyncThunk(
	'account/setCurrencies',
	async (currency: ISetCurrency) => {
		try {
			const res = await axiosInstance.put<ISetCurrencyResponse>(
				'common/set-currency',
				currency
			)
			setAuthUser({
				firstName: res.data.user.personalInformation.firstName,
				lastName: res.data.user.personalInformation.lastName,
				profilePic: res.data.user.personalInformation.profilePic,
				email: res.data.user.credentialDetails.email,
				role: res.data.user.role,
				loyaltyPoints: res.data.user.loyaltyPoints,
				ageVerification: res.data.user.personalInformation.idCard.status,
				phoneNumber: res.data.user.personalInformation.phoneNumber,
				dob: res.data.user.personalInformation.dob,
				gender: res.data.user.personalInformation.gender,
				currency: res.data.user.preferences.currency.name,
			})
			return res.data
		} catch (e) {
			notificationSystem('Something went wrong', '', true)
		}
	}
)
