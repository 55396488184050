import { Carousel } from '@mantine/carousel'
import {
	Accordion,
	Avatar, Box,
	Button,
	Container,
	em,
	Grid,
	Group,
	Image,
	List,
	Paper,
	SimpleGrid,
	Text,
	ThemeIcon,
	Title,
} from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'
import { IconCheck, TablerIconsProps } from '@tabler/icons-react'
import { featuresData } from '../mockdata/FeaturesData'
import reviews from '../mockdata/reviews'
import classes from './AboutUs.module.css'
import bgImage from "../assets/tigg/About Us.png"

interface FeatureProps {
	icon: (props: TablerIconsProps) => JSX.Element
	title: React.ReactNode
	description: React.ReactNode
}

export function Feature({ icon: Icon, title, description }: FeatureProps) {
	return (
		<div>
			<ThemeIcon variant='light' size={40} radius={40}>
				<Icon size={18} stroke={1.5} />
			</ThemeIcon>
			<Text mt='sm' mb={7}>
				{title}
			</Text>
			<Text size='sm' c='dimmed' lh={1.6}>
				{description}
			</Text>
		</div>
	)
}

const AboutUs = () => {
	const features = featuresData.map((feature, index) => (
		<Feature {...feature} key={index} />
	))

	const isMobile = useMediaQuery(`(max-width: ${em(768)})`)

	const reviewCard = reviews?.map((review, index) => {
		return (
			<Carousel.Slide>
				<Paper
					w={isMobile ? '100%' : 450}
					p={20}
					key={index}
					withBorder
					radius='md'
					className={classes.comment}
				>
					<Group>
						<Avatar
							src='https://raw.githubusercontent.com/mantinedev/mantine/master/.demo/avatars/avatar-2.png'
							alt='Jacob Warnhalter'
							radius='xl'
						/>
						<div>
							<Text fz='sm'>{review?.name}</Text>
							<Text fz='xs' c='dimmed'>
								{review?.reviewDate}
							</Text>
						</div>
					</Group>
					<Text>{review?.message}</Text>
				</Paper>
			</Carousel.Slide>
		)
	})

	return (
		<>
			<div className={classes.hero}>
				{/* <Overlay color='#000' opacity={0.65} zIndex={1} /> */}

				<Box h={'100%'} w={'100%'}>
					<Image src={bgImage} h={'100%'} />
				</Box>
			</div>
			<Container size={isMobile ? '100%' : '80%'}>
				<div className={classes.inner}>
					<div className={classes.content}>
						<Title className={classes.title}>
							Welcome to{' '}
							<span className={classes.highlight}>
								Your Favorite Shopping Hub
							</span>{' '}
							<br /> A Modern E-commerce Platform
						</Title>
						<Text c='dimmed' mt='md'>
							Shop for all your essentials in one place! From daily groceries to
							indulgent treats, our platform brings you a wide selection of
							high-quality products delivered right to your doorstep.
						</Text>

						<List
							mt={30}
							spacing='sm'
							size='sm'
							icon={
								<ThemeIcon size={20} radius='xl'>
									<IconCheck size={12} stroke={1.5} />
								</ThemeIcon>
							}
						>
							<List.Item>
								<b>Premium Pet Foods</b> – Ensure your furry friends stay happy
								and healthy with our selection of nutritious pet foods.
							</List.Item>
							<List.Item>
								<b>Fresh Groceries</b> – Shop for fresh vegetables, pantry
								staples, and everything you need for your kitchen.
							</List.Item>
							<List.Item>
								<b>Juicy Fruits</b> – Enjoy seasonal and exotic fruits
								handpicked for freshness and quality.
							</List.Item>
							<List.Item>
								<b>Delicious Ice Cream</b> – Treat yourself to a variety of
								creamy, flavorful ice creams for every craving.
							</List.Item>
							<List.Item>
								<b>Refreshing Beverages</b> – Stock up on juices, sodas, and
								more to quench your thirst.
							</List.Item>
							<List.Item>
								<b>Tasty Snacks</b> – Discover a range of snacks perfect for
								every occasion, from chips to cookies.
							</List.Item>
							<List.Item>
								<b>Convenient Delivery</b> – Enjoy quick and reliable delivery
								services tailored to your schedule.
							</List.Item>
							<List.Item>
								<b>Exclusive Deals</b> – Save big with discounts and offers on
								your favorite items.
							</List.Item>
						</List>

						<Group mt={30}>
							<Button radius='xl' size='md' className={classes.control}>
								Get started
							</Button>
							<Button
								variant='default'
								radius='xl'
								size='md'
								className={classes.control}
							>
								View Products
							</Button>
						</Group>
					</div>
					<Image
						src={'https://ui.mantine.dev/_next/static/media/image.9a65bd94.svg'}
						className={classes.image}
					/>
				</div>
			</Container>
			<Container size={isMobile ? '100%' : '80%'} className={classes.wrapper}>
				<Title className={classes.title}>
					Integrate effortlessly with any technology stack
				</Title>

				<Container size={560} p={0}>
					<Text size='sm' className={classes.description}>
						"Exceptional Features That Make Us Stand Out"
					</Text>
				</Container>

				<SimpleGrid
					mt={60}
					cols={{ base: 1, sm: 2, md: 3 }}
					spacing={{ base: 'xl', md: 50 }}
					verticalSpacing={{ base: 'xl', md: 50 }}
				>
					{features}
				</SimpleGrid>
			</Container>
			<div className={classes.wrapper}>
				<Container my={isMobile ? 0 : 50} size={isMobile ? '100%' : '80%'}>
					<Grid id='faq-grid' gutter={50}>
						<Grid.Col span={{ base: 12, md: 6 }}>
							<Image
								src={
									'https://ui.mantine.dev/_next/static/media/image.b0c2306b.svg'
								}
								alt='Frequently Asked Questions'
							/>
						</Grid.Col>
						<Grid.Col span={{ base: 12, md: 6 }}>
							<Title order={2} ta='left' className={classes.title}>
								Frequently Asked Questions
							</Title>

							<Accordion
								chevronPosition='right'
								defaultValue='delivery-options'
								variant='separated'
							>
								<Accordion.Item
									className={classes.item}
									value='delivery-options'
								>
									<Accordion.Control>
										What delivery options are available?
									</Accordion.Control>
									<Accordion.Panel>
										We offer multiple delivery options, including standard,
										express, and same-day delivery for selected locations.
										Delivery times and charges vary based on your location and
										the selected option.
									</Accordion.Panel>
								</Accordion.Item>

								<Accordion.Item className={classes.item} value='return-policy'>
									<Accordion.Control>
										What is your return policy?
									</Accordion.Control>
									<Accordion.Panel>
										You can return items within 14 days of receiving your order.
										Products must be unused, undamaged, and in their original
										packaging. Some items, like perishable goods, may not be
										eligible for return.
									</Accordion.Panel>
								</Accordion.Item>

								<Accordion.Item
									className={classes.item}
									value='multiple-accounts'
								>
									<Accordion.Control>
										Can I create more than one account?
									</Accordion.Control>
									<Accordion.Panel>
										Yes, you can create multiple accounts, but we recommend
										using one account to keep track of your orders and
										preferences conveniently.
									</Accordion.Panel>
								</Accordion.Item>

								<Accordion.Item className={classes.item} value='secure-payment'>
									<Accordion.Control>
										Are my payment details secure?
									</Accordion.Control>
									<Accordion.Panel>
										Absolutely. We use industry-standard encryption and secure
										payment gateways to ensure that your credit card and
										personal details remain safe.
									</Accordion.Panel>
								</Accordion.Item>

								<Accordion.Item
									className={classes.item}
									value='newsletter-subscription'
								>
									<Accordion.Control>
										How can I subscribe to your newsletter?
									</Accordion.Control>
									<Accordion.Panel>
										You can subscribe to our newsletter by entering your email
										address in the subscription box located at the bottom of the
										homepage. Stay updated with exclusive deals and product
										launches!
									</Accordion.Panel>
								</Accordion.Item>
							</Accordion>
						</Grid.Col>
					</Grid>
				</Container>
			</div>
			<Container my={isMobile ? 10 : 40} size={isMobile ? '100%' : '80%'}>
				<Title my={'lg'} ta={'center'}>
					Why Our Customers Choose Us
				</Title>
				<Carousel
					withIndicators
					height={200}
					slideSize={{ base: '100%', sm: '50%', md: '33.333333%' }}
					slideGap={{ base: 'md', sm: 'md' }}
					loop
					align='start'
					slidesToScroll={1}
				>
					{reviewCard}
				</Carousel>
			</Container>
		</>
	)
}

export default AboutUs
