import {
	Alert,
	Card,
	ComboboxItem, em,
	Flex,
	Grid,
	LoadingOverlay,
	Select,
	Text,
	Title,
} from '@mantine/core'
import { IconInfoCircle, IconTrendingUp } from '@tabler/icons-react'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import SparkLineCard from '../../../components/SparkLineCard.tsx'
import {
	getAllBusinessesNamesAction,
	getDashboardStatsAction,
} from '../../../store/business/businessesActions.ts'
import { useAppDispatch, useAppSelector } from '../../../store/store.ts'
import {useMediaQuery} from "@mantine/hooks";

export interface IAllBusinessesNames {
	success: boolean
	businesses: Business[]
}

export interface Business {
	_id: string
	title: string
	image: string
}

export const data = [
	{
		date: 'Mar 22',
		activeOrders: 2890,
		completedOrder: 2338,
	},
	{
		date: 'Mar 23',
		activeOrders: 2756,
		completedOrder: 2103,
	},
	{
		date: 'Mar 24',
		activeOrders: 3322,
		completedOrder: 986,
	},
	{
		date: 'Mar 25',
		activeOrders: 3470,
		completedOrder: 2108,
	},
	{
		date: 'Mar 26',
		activeOrders: 3129,
		completedOrder: 1726,
	},
]

const Dashboard = () => {
	const { dashboardStats, loaders, businessesNames } = useAppSelector(
		(state) => state.business
	)
	const dispatch = useAppDispatch()
	const navigate = useNavigate()
	const [value, setValue] = useState<ComboboxItem | null>(null)
	const isMobile = useMediaQuery(`(max-width: ${em(768)})`);


	useEffect(() => {
		dispatch(getAllBusinessesNamesAction())
		if (value) dispatch(getDashboardStatsAction(value.value))
		console.log('selected business', value?.value)
	}, [dispatch, value])

	return (
		<Flex direction='column' gap='lg' mb='xs' m='md'>
			{dashboardStats?.stats?.warning?.type === 'warning' && (
				<Alert
					style={{ cursor: 'pointer' }}
					onClick={() => navigate('/seller/new-business')}
					variant='light'
					color='red'
					title={dashboardStats?.stats?.warning?.message}
					icon={<IconInfoCircle />}
				></Alert>
			)}
			<LoadingOverlay visible={loaders.gettingDashboardStats} />
			<Flex direction={isMobile ? 'column' : 'row'} w={'100%'} justify={'space-between'} align={isMobile ? 'start' : 'center'}>
				<Title order={3}>Dashboard</Title>
				<Select
					placeholder='Select Business'
					w={isMobile ? '100%' : '50%'}
					size='lg'
					data={businessesNames?.businesses?.map((business) => ({
						value: business._id,
						label: business.title,
					}))}
					radius={'md'}
					onChange={(_value, option) => {
						setValue(option)
					}}
				/>
			</Flex>
			<Grid>
				{dashboardStats?.stats?.mySales?.map((sale, index) => {
					return (
						<Grid.Col span={{ base: 12, md: 6, lg: 3 }} key={index}>
							<SparkLineCard sale={sale} />
						</Grid.Col>
					)
				})}
			</Grid>
			<Flex>
				<Text fw={600}>My Orders</Text>
			</Flex>
			<Grid>
				{dashboardStats?.stats?.myOrders?.map((myOrder, index) => {
					return (
						<Grid.Col key={index} span={{ base: 6, md: 6, lg: 3 }}>
							<Card radius='lg' bg='white'>
								<Flex direction='column' w='100%' align='center' gap='xs'>
									<Title>{myOrder?.count}</Title>
									<IconTrendingUp color='#20C375' />
									<Text ta={'center'}>{myOrder?.title}</Text>
								</Flex>
							</Card>
						</Grid.Col>
					)
				})}
			</Grid>
		</Flex>
	)
}

export default Dashboard
