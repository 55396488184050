import { notificationSystem } from './notificationSystem.ts'
import { AxiosError } from 'axios'

export const errorHandler = (e: AxiosError) => {
	if (e?.response?.status === 401) {
		localStorage.clear()
		notificationSystem('Something went wrong', 'Please sign in again', true)
		return // Stop further execution after handling 401
	}

	// If no specific status code is matched, show the generic error message
	notificationSystem('Something went wrong', e.message, true)
}
