import {
	Avatar,
	Box,
	Center,
	Combobox,
	em,
	Flex,
	Highlight,
	Loader,
	Pill,
	Text,
	TextInput,
	useCombobox,
} from '@mantine/core'
import { useDebouncedValue, useMediaQuery } from '@mantine/hooks'
import { IconChevronLeft, IconSearch, IconX } from '@tabler/icons-react'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Product } from '../interfaces/buyer/product/ISingleProductResponse'
import {
	clearSearchHistory,
	getRecentSearchesAction,
	searchProductsAction,
} from '../store/product/productActions'
import { useAppDispatch, useAppSelector } from '../store/store'
import { notificationSystem } from '../utils/notificationSystem'

// const selectData = ['🍎 Apples', '🍌 Bananas', '🥦 Broccoli', '🥕 Carrots', '🍫 Chocolate'];

export interface ISearchHistory {
	success: boolean
	searches: string[]
}

export interface ISearchProducts {
	success: boolean
	length: number
	products: Product[]
}

const CustomMultiSelect = () => {
	const combobox = useCombobox({
		onDropdownClose: () => combobox.resetSelectedOption(),
		onDropdownOpen: () => combobox.updateSelectedOptionIndex('active'),
	})
	const { allRecentSearches, loaders, searchedProducts } = useAppSelector(
		(state) => state.product
	)
	const [search, setSearch] = useState('')
	// const [data, setData] = useState(selectData)
	const [value, setValue] = useState<string[]>([])
	const navigate = useNavigate()
	const isMobile = useMediaQuery(`(max-width: ${em(768)})`)
	const isLaptop = useMediaQuery(`(max-width: ${em(1200)})`)
	const [debounced] = useDebouncedValue(search, 2000)
	const dispatch = useAppDispatch()
	// const exactOptionMatch = data?.some((item) => item === search)

	// const filteredOptions = useMemo(() => (
	//     data?.filter((item) => item?.toLowerCase().includes(search.trim().toLowerCase()))
	// ), [data, search]);

	useEffect(() => {
		dispatch(searchProductsAction(debounced))
	}, [debounced, dispatch])

	const shouldFilterOptions = !searchedProducts?.products?.some(
		(item) => item?.productName === search
	)

	const filteredOptions = shouldFilterOptions
		? searchedProducts?.products?.filter((item) =>
				item?.productName?.toLowerCase().includes(search?.toLowerCase().trim())
		  ) || [] // Fallback to an empty array if undefined
		: searchedProducts?.products || [] // Fallback to an empty array if undefined

	useEffect(() => {
		dispatch(getRecentSearchesAction())
	}, [])

	const handleValueSelect = (val: string) => {
		setSearch(val)
	}

	const handleValueRemove = (val: string) =>
		setValue((current) => current.filter((v) => v !== val))

	const values = value.map((item, index) => (
		<Pill key={index} withRemoveButton onRemove={() => handleValueRemove(item)}>
			{item}
		</Pill>
	))

	console.log(values)

	// const options = data
	// 	?.filter((item) => item.toLowerCase().includes(search.trim().toLowerCase()))
	// 	.map((item, index) => (
	// 		<Combobox.Option value={item} key={index} active={value.includes(item)}>
	// 			<Group gap='lg' align='centre'>
	// 				{value.includes(item) ? (
	// 					<CheckIcon size={12} />
	// 				) : (
	// 					<IconSearch size='23px' />
	// 				)}
	// 				<span>{item}</span>
	// 			</Group>
	// 		</Combobox.Option>
	// 	))

	const options = filteredOptions.slice(0, 8).map((item) => (
		<Combobox.Option value={item?.productName} key={item?._id}>
			<Flex
				align={'center'}
				gap={10}
				onClick={() => {
					navigate(`/products/${item?._id}`, {
						state: { search: true },
					})
					combobox.closeDropdown()
				}}
			>
				<Box>
					<Avatar src={item?.productImage} size={'lg'} />
				</Box>
				<Highlight highlight={search} size='sm'>
					{item?.productName}
				</Highlight>
			</Flex>
		</Combobox.Option>
	))

	// useEffect(() => {
	// 	setData(selectData)
	// }, [selectData])

	const handleClearHistory = async () => {
		dispatch(clearSearchHistory()).then(() => {
			notificationSystem('History Clear Successfully', '', false)
			combobox.openDropdown()
		})
	}

	return (
		<>
			<Flex w={isMobile ? '100%' : isLaptop ? '75%' : '55%'} align={'center'}>
				{isMobile && <IconChevronLeft size={30} onClick={() => navigate(-1)} style={{ marginTop: 15 }} />}

				<Combobox
					variant='unstyled'
					radius='lg'
					size={isMobile ? 'lg' : 'sm'}
					store={combobox}
					onOptionSubmit={handleValueSelect}
					withinPortal={false}
				>
					<Combobox.DropdownTarget>
						{/*<Pill.Group>*/}
						{/*{values}*/}

						<TextInput
							w={isMobile ? '97%' : isLaptop ? '100%' : '80%'}
							onFocus={() => combobox.openDropdown()}
							value={search}
							radius={'lg'}
							mx={isMobile ? 'auto' : undefined}
							mt={isMobile ? 15 : 0}
							leftSection={<IconSearch />}
							placeholder='Search Tiger it'
							onChange={(event) => {
								combobox.updateSelectedOptionIndex()
								setSearch(event.currentTarget.value)
							}}
							rightSection={
								search.length > 0 ? (
									<IconX size={19} onClick={() => setSearch('')} />
								) : (
									''
								)
							}
							onKeyDown={(event) => {
								if (event.key === 'Backspace' && search.length === 0) {
									event.preventDefault()
									handleValueRemove(value[value.length - 1])
								}

								if (event.key === 'Enter') {
									if (value.length >= 0)
										dispatch(searchProductsAction(debounced))
									navigate('/search-products-buyer', {
										state: { search: search },
									})
									combobox.closeDropdown()
								}
							}}
							onClick={() => {
								dispatch(getRecentSearchesAction())
							}}
						/>

						{/*</Pill.Group>*/}
					</Combobox.DropdownTarget>
					{!isMobile && (
						<Combobox.Dropdown>
							<Combobox.Options>
								{options.length === 0 ? (
									<Combobox.Empty></Combobox.Empty>
								) : loaders.searchingProducts ? (
									<Flex w={'100%'} h={'200px'} justify={'center'}>
										<Loader type='dots' />
									</Flex>
								) : (
									options
								)}
							</Combobox.Options>

							<Flex direction={'column'} my={20}>
								<Flex w={'100%'} px={20} justify={'space-between'}>
									<Text>Recent Searches</Text>
									<Text
										c={'gray'}
										style={{
											cursor: 'pointer',
											textDecoration: 'underline',
										}}
										fz={'xs'}
										onClick={handleClearHistory}
									>
										Clear History
									</Text>
								</Flex>

								<Pill.Group m={20}>
									{loaders.gettingAllRecentSearches ? (
										<>
											<Flex
												justify={'center'}
												h={'10vh'}
												align={'center'}
												w={'100%'}
											>
												<Loader type={'dots'} />
											</Flex>
										</>
									) : allRecentSearches?.searches?.length > 0 ? (
										allRecentSearches?.searches?.slice(0, 5).map((_, index) => {
											return (
												<Pill
													style={{
														cursor: 'pointer',
													}}
													onClick={() => {
														console.log('value', _)
														setSearch(_)
														dispatch(searchProductsAction(debounced))
														navigate('/search-products-buyer', {
															state: { search: _ },
														})
														// combobox.closeDropdown()
														// dispatch(productSearch(debounced))
														// navigate('/search-products', {
														// 	state: { search: _ },
														// })
													}}
													size='md'
													key={index}
												>
													{_}
												</Pill>
											)
										})
									) : (
										<Center w={'100%'} my={10}>
											<Text fz={'xs'}>Not History Found</Text>
										</Center>
									)}
								</Pill.Group>
							</Flex>
						</Combobox.Dropdown>
					)}
					{isMobile && (
						<>
							{options.length > 0 && (
								<Combobox.Dropdown>
									<Combobox.Options>
										{loaders.searchingProducts ? (
											<Flex w={'100%'} h={'200px'} justify={'center'}>
												<Loader type='dots' />
											</Flex>
										) : (
											options
										)}
									</Combobox.Options>
								</Combobox.Dropdown>
							)}
						</>
					)}
				</Combobox>
			</Flex>

			{/* recent for mobile */}
			{isMobile && (
				<>
					<Flex direction={'column'} my={20}>
						<Flex w={'100%'} px={10} justify={'space-between'}>
							<Text>Recent Searches</Text>
							<Text
								c={'gray'}
								style={{
									cursor: 'pointer',
									textDecoration: 'underline',
								}}
								fz={'xs'}
								onClick={handleClearHistory}
							>
								Clear History
							</Text>
						</Flex>

						<Pill.Group m={10}>
							{loaders.gettingAllRecentSearches ? (
								<>
									<Flex
										justify={'center'}
										h={'10vh'}
										align={'center'}
										w={'100%'}
									>
										<Loader type={'dots'} />
									</Flex>
								</>
							) : allRecentSearches?.searches?.length > 0 ? (
								allRecentSearches?.searches?.slice(0, 10).map((_, index) => {
									return (
										<Pill
											style={{
												cursor: 'pointer',
											}}
											onClick={() => {
												console.log('value', _)
												setSearch(_)
												dispatch(searchProductsAction(debounced))
												navigate('/search-products-buyer', {
													state: { search: _ },
												})
												// combobox.closeDropdown()
												// dispatch(productSearch(debounced))
												// navigate('/search-products', {
												// 	state: { search: _ },
												// })
											}}
											size='md'
											key={index}
										>
											{_}
										</Pill>
									)
								})
							) : (
								<Center w={'100%'} my={10}>
									<Text fz={'xs'}>Not History Found</Text>
								</Center>
							)}
						</Pill.Group>
					</Flex>
				</>
			)}
		</>
	)
}

export default CustomMultiSelect
